<template>
  <div>
    <p class="font-weight-bolder mb-2">
      ใส่คำตอบ [ระบบจะทำการเช็คคำตอบที่ถูกต้องให้]
    </p>
    <p v-if="!textareaValue" class="text-danger">
      ยังไม่กำหนดคำตอบของคำถาม
    </p>
    <b-form-textarea
      v-model="textareaValue"
      @change="generateAnswerFillBlank($event)"
    >
    </b-form-textarea>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  namespaced: true,
  props: ["questionId", "questionIndex", "sectionIndex"],
  data() {
    return {
      token: localStorage.getItem("token"),
      textareaValue: "",
      answer: []
    };
  },
  mounted() {},

  computed: {
    ...mapGetters("ExamBuilder", ["exam", "allAnswer", "hasAnswers"]) ,
  },

  created() {
    this.initTextareaValue(this.questionId);
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "fetchQuestionAnswer",
      "updateQuestionAnswer",
      "createQuestionAnswer",
      "deleteQuestionAnswer",
      "updateQuestionFillBlankInfo",
      "setQuestionAnswered",
      "setQuestionUnanswered",
      "setSolution"
    ]),

    async initTextareaValue(questionId) {
      if (this.hasAnswers[questionId]) {
        this.answer = this.allAnswer.find(item => item.questionId === questionId)
        this.textareaValue = this.answer.answerText;
        this.setSolution(questionId, this.answer.solution)
      } else {
        this.setSolution(questionId, "")
      }
    },

    async generateAnswerFillBlank(textFormEditor) {
      if (textFormEditor == "" || textFormEditor == null) {
        this.textareaValue = "";
        this.deleteAnswerFillBlank();
        return;
      }

      if ( this.hasAnswers[this.questionId] ) {
        this.updateAnswerFillBlank(textFormEditor);
      } else {
        this.createAnswerFillBlank(textFormEditor);
      }
    },

    updateAnswerFillBlank(textFormEditor) {
      let requestAnswer = {
        body: {
          questionFillBlankId: textFormEditor.trim(),
          answerText: textFormEditor.trim(),
        },
        questionId: this.questionId,
        token: this.token,
      };
      this.updateQuestionAnswer(requestAnswer);
    },

    createAnswerFillBlank(textFormEditor) {
      let requestFillBlankUpdate = {
        body: {
          questionFillBlankId: textFormEditor.trim(),
          answerText: textFormEditor.trim(),
        },
        questionId: this.questionId,
        token: this.token,
        sectionIndex: this.sectionIndex,
      };
      this.createQuestionAnswer(requestFillBlankUpdate);

      let request = {
        body: {
          type: "FILL_BLANK",
          hasAnswer: true,
        },
        questionId: this.questionId,
        token: this.token,
        sectionIndex: this.sectionIndex,
        examId: this.exam.id,
      };
      this.updateQuestionFillBlankInfo(request);
      this.setQuestionAnswered(this.questionId)
    },

    deleteAnswerFillBlank() {
      let requestFillBlankUpdate = {
        questionId: this.questionId,
        token: this.token,
      };
      this.deleteQuestionAnswer(requestFillBlankUpdate)
      let infoRequest = {
        body: {
          type: "FILL_BLANK",
          hasAnswer: false,
        },
        questionId: this.questionId,
        token: this.token,
      };

      this.updateQuestionFillBlankInfo(infoRequest);
      this.setQuestionUnanswered(this.questionId)
    },
  },
};
</script>
<style lang="css" scope>
.editor-button {
  display: flex;
  justify-content: flex-end;
}
</style>
