<template>
  <div class="">
    <draggable
      :list="questions"
      tag="div"
      animation="200"
      :disabled="isDraggingDisabled"
      @change="updateOrderQuestion()"
      handle=".handle"
    >
      <div
        class="mt-4 questions-background"
        v-for="(question, questionIndex) in questions"
        :key="questionIndex"
      >
        <ul>
          <li class="">
            <div class="question-info handle">
              <div class="exams-question handle">
                <img
                  src="/images/icons/icon_movable.png"
                  alt="movable"
                  height="20"
                  class="align-middle pointer icon-move-sections handle my-auto"
                />
                <p class="mr-2">คำถาม:</p>
                <div class="text-truncate exams-question-markdown">
                  {{ question.title }}
                </div>
              </div>
              <div class="exams-question-buttons d-flex align-items-center">
                <b-button
                  class="exams-delete-button mx-3"
                  variant="danger"
                  size="sm"
                  @click="confirmRemoveQuestions(question.id, questionIndex)"
                >
                  <b-icon
                    icon="trash-fill"
                    aria-hidden="true"
                    class="align-middle"
                  ></b-icon>
                </b-button>

                <p @click="toggleCollapse(question.id)" class="pointer">
                  <b-icon
                    :icon="getCollapseIcon(question.id)"
                    scale="2"
                  ></b-icon>
                </p>
              </div>
            </div>
            <b-collapse
              visible
              :id="question.id"
              v-model="collapsedQuestions[question.id]"
            >
              <b-row>
                <b-col :cols="question.type === 'PASSAGE' || isBundle ? 12 : 11">
                  <div class="show-edit-question mb-2 mt-4">
                    <h5
                      v-if="question.type != 'PASSAGE'"
                      class="font-weight-bold my-auto"
                    >
                      คำถาม
                    </h5>
                    <h5 v-else class="font-weight-bold my-auto">บทความ</h5>
                    <b-button
                      class="ml-2"
                      variant="outline-primary"
                      size="sm"
                      @click="
                        editQuestionInfo(
                          question.title,
                          question.id,
                          questionIndex
                        )
                      "
                    >
                      <b-icon
                        icon="pencil-square"
                        class="align-middle"
                      ></b-icon>
                      <span v-if="question.type != 'PASSAGE'"> แก้ไขคำถาม</span>
                      <span v-else> แก้ไขบทความ</span>
                    </b-button>
                  </div>
                  <div class="edit-question">
                    <b-form-input
                      autocomplete="off"
                      v-model="question.title"
                      :state="titleQuestionState[questionIndex]"
                      trim
                      @change="
                        editQuestionDirectly(
                          question.title,
                          question.id,
                          questionIndex
                        )
                      "
                    ></b-form-input>
                  </div>
                </b-col>
                <b-col cols="1" v-if="!(question.type === 'PASSAGE' || isBundle)">
                  <div class="show-edit-question mb-2 mt-4">
                    <h6 class="mt-2 mb-1">คะแนน</h6>
                  </div>
                  <div>
                    <b-form-input
                      v-model="question.score"
                      trim
                      @change="updateScore(question.score, question.id)"
                      :formatter="formatterNumber"
                    ></b-form-input>
                  </div>
                </b-col>
              </b-row>

              <hr class="my-3" />
              <PassageAndBundleQuestion
                v-if="question.type == 'PASSAGE' || question.type == 'BUNDLE'"
                :section="section"
                :question="question"
                :questionIndex="questionIndex"
                :sectionIndex="sectionIndex"
                :isBundle="question.type === 'BUNDLE' ? true : false"
                :key="componentKey"
              />

              <MultiChoiceQuestion
                v-if="question.type == 'MULTIPLE_CHOICE'"
                :question="question"
                :choices="question._choices"
                :isSubQuestion="isSubQuestion"
                :mainQuestionId="mainQuestionId"
                :key="componentKey"
              />

              <FillBlankQuestion
                v-if="question.type == 'FILL_BLANK'"
                :questionId="question.id"
                :questionIndex="questionIndex"
                :sectionIndex="sectionIndex"
                :key="componentKey"
              />

              <hr class="my-3" v-if="question.type != 'PASSAGE' && question.type != 'BUNDLE'" />

              <div v-if="question.type != 'PASSAGE' && question.type != 'BUNDLE'">
                <div class="desc-answer mb-2">
                  <div>
                    <span class="font-weight-bolder">
                      คำอธิบายเฉลย
                    </span>
                    <span v-if="!hasAnswers[question.id]" class="text-danger" >
                      กำหนดคำตอบก่อนกรอกคำอธิบายเฉลย
                    </span>
                  </div>
                  
                  
                  <p
                    v-b-toggle="question.id + questionIndex"
                    @click="onShowAnswerSolution(question.id)"
                  >
                    <b-icon class="down" icon="chevron-down" scale="2"></b-icon>
                    <b-icon class="up" icon=" chevron-up" scale="2"></b-icon>
                  </p>
                </div>
                <b-collapse
                  :id="question.id + questionIndex"
                  accordion="answer-desc"
                >
                  <div :class="{ 'disabled-div': isExamEditorDisabled(question.id) }">
                    <ExamEditor
                      :editorText="solutions[question.id]"
                      :EditType="markdown"
                      :showButton="false"
                      @updateDesc="updateDescription($event, question.id)"
                    />
                  </div>
                </b-collapse>
              </div>
            </b-collapse>
          </li>
        </ul>
      </div>
    </draggable>
    <b-modal
      v-model="ModalEditQuestion"
      title="ตั้งคำถาม"
      size="xl"
      :hide-footer="true"
      ref="modal-editor"
      modal-class="edit-question-modal"
    >
      <ExamEditor
        :sectionId="section.id"
        :editorText="editorText"
        :questionId="questionId"
        :sectionIndex="sectionIndex"
        :EditType="markdown"
        @hideModal="hideModal($event)"
        @onSave="onUpdateQuestionTitle($event, questionIndex)"
      />
    </b-modal>
    <pre></pre>
  </div>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor.css";
import draggable from "csq-vue-multi-draggable";
import { mapActions, mapGetters } from "vuex";
import ExamEditor from "../modal/ModalExamEditor.vue";
import MultiChoiceQuestion from "./MultiChoiceQuestion.vue";
import FillBlankQuestion from "./FillBlankQuestion.vue";
import PassageAndBundleQuestion from "./PassageAndBundleQuestion.vue";

export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      examId: this.$route.params.quizExamId,
      ModalEditQuestion: false,
      questionScore: "",
      markdown: "markdown",
      errorMessage: "",
      solution: "",
      isDraggingDisabled: false,
      titleQuestionState: [],
      componentKey: 0,
      hasAnswer: true,

      //TitleEditor
      editorText: "editorText",
      questionId: "",
      questionIndex: null,
    };
  },
  props: {
    questions: Array,
    section: Object,
    sectionIndex: Number,

    //subQuestion
    isSubQuestion: {
      type: Boolean,
      default: false,
    },
    mainQuestionId: {
      type: String,
      default: "",
    },
    mainQuestionIndex: {
      type: Number,
      default: 0,
    },
    isBundle: Boolean,
  },

  components: {
    draggable,
    ExamEditor,
    MultiChoiceQuestion,
    PassageAndBundleQuestion,
    FillBlankQuestion,
  },

  mounted() {
    this.questions.forEach((question) => {
      this.addCollapsedQuestions(question.id);
    });
  },

  computed: {
    ...mapGetters("ExamBuilder", ["exam", "answer", "allAnswer", "collapsedQuestions", "hasAnswers", "solutions", ]),
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "findExamsbyId",
      "addCollapsedQuestions",
      "toggleCollapsedQuestions",
      "deleteQuestion",
      "deleteSubQuestion",
      "updateQuestionInfo",
      "updateSubQuestionInfo",
      "updateQuestionOrder",
      "updateSubQuestionOrder",
      "fetchQuestionAnswer",
      "fetchQuestionAllAnswer",
      "createQuestionAnswer",
      "updateQuestionAnswer",
      "createSubQuestionAnswer",
      "updateSubQuestionAnswer",
      "setQuestionAnswered",
      "setQuestionUnanswered",
      "setSolution",
      "setNoSolution",
      "countSectionAndQuestion"
    ]),

    isExamEditorDisabled(questionId) {
      return !this.hasAnswers[questionId];
    },

    toggleCollapse(questionId) {
      this.toggleCollapsedQuestions(questionId)
    },

    getCollapseIcon(questionId) {
      return this.collapsedQuestions[questionId]
        ? "chevron-up"
        : "chevron-down";
    },

    formatterNumber(score) {
      if (!!score && Math.abs(score) >= 0) {
        return Math.abs(score);
      } else {
        return null;
      }
    },

    async updateDescription(textFormEditor, questionId) {
      let requestAnswer = {
        body: {
          solution: textFormEditor,
        },
        questionId: this.isSubQuestion ? this.mainQuestionId : questionId,
        subQuestionId: this.isSubQuestion ? questionId : null,
        token: this.token,
        
      };
        
      if( this.hasAnswers[questionId] ) {
        if( !this.isSubQuestion ){ 
            this.updateQuestionAnswer(requestAnswer);
        } else {
            this.updateSubQuestionAnswer(requestAnswer);
        }
      }
    },

    async updateScore(questionScore, questionId) {
      if (Number(questionScore) < 0 || Number(questionScore) > 100) {
        return;
      } else {
        this.questionScore = questionScore;
        this.questionId = questionId;

        const request = {
          body: {
            score: questionScore,
          },
          token: this.token,
          questionId: this.isSubQuestion ? this.mainQuestionId : questionId,
          subQuestionId: this.isSubQuestion ? questionId : null,
        };

        if (this.isSubQuestion) {
          await this.updateSubQuestionInfo(request);
        } else {
          await this.updateQuestionInfo(request);
        }
      }
    },

    async updateOrderQuestion() {
      try {
        this.isDraggingDisabled = true;
        for (let i = 0; i < this.questions.length; i++) {
          this.questions[i].index = i;
          if (this.isSubQuestion) {
            let request = {
              body: {
                index: this.questions[i].index,
                title: this.questions[i].title
              },
              id: this.mainQuestionId,
              subId: this.questions[i].id,
              token: this.token,
              sectionIndex: this.sectionIndex,
              questionIndex: this.mainQuestionIndex,
              subQuestionIndex: this.questions[i].index,
              examId: this.examId,
            };
            await this.updateSubQuestionOrder(request);
          } else {
            let request = {
              body: {
                index: this.section.questions[i].index,
                title: this.section.questions[i].title
              },
              id: this.section.questions[i].id,
              token: this.token,
              sectionIndex: this.sectionIndex,
              examId: this.examId,
              questionsIndex: this.section.questions[i].index,
            };
            this.updateQuestionOrder(request);
          }
        }
        await this.fetchQuestionAllAnswer(this.examId);
        setTimeout(() => {
          this.componentKey += 1;
        }, 300);
        this.isDraggingDisabled = false;
      } catch (error) {
        console.error("Error updating question order:", error);
        this.isDraggingDisabled = false;
      }
    },

    confirmRemoveQuestions(questionId, questionIndex) {
      this.$bvModal
        .msgBoxConfirm(`ต้องการลบคำถามนี้ใช่หรือไม่?`, {
          title: "แจ้งเตือนการลบ",
          size: "md",
          buttonSize: "sm px-3",
          okVariant: "primary",
          titleClass: "p-2",
          okTitle: " ใช่ ",
          cancelTitle: "ยกเลิก",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) this.handleDeleteQuestion(questionId, questionIndex);
        });
    },

    async handleDeleteQuestion(questionId) {
      if (this.isSubQuestion) {
        let request = {
          id: this.$route.params.quizExamId,
          sectionId: this.section.id,
          questionId: this.mainQuestionId,
          questionIndex: this.mainQuestionIndex,
          subQuestionId: questionId,
          token: this.token,
          sectionIndex: this.sectionIndex,
        };
        await this.deleteSubQuestion(request);

      } else {
        let request = {
          id: this.$route.params.quizExamId,
          sectionId: this.section.id,
          questionId: questionId,
          token: this.token,
          sectionIndex: this.sectionIndex,
        };
        await this.deleteQuestion(request);
      }

      setTimeout(() => {
          this.countSectionAndQuestion(this.$route.params.quizExamId);
      }, 500);
      this.questions = this.questions.filter(question => question.id !== questionId);
      this.componentKey += 1;
    },

    async setQuestionInfo(questionTitle, questionId, questionIndex) {
      this.editorText = questionTitle;
      this.questionId = questionId;
      this.questionIndex = questionIndex;
    },

    editQuestionInfo(questionTitle, questionId, questionIndex) {
      this.ModalEditQuestion = true;
      this.setQuestionInfo(questionTitle, questionId, questionIndex);
    },

    editQuestionDirectly(questionTitle, questionId, questionIndex) {
      this.setQuestionInfo(questionTitle, questionId, questionIndex);
      this.onUpdateQuestionTitle(questionTitle, questionIndex);
    },

    onUpdateQuestionTitle(textFormEditor, questionIndex) {
      if (textFormEditor == "" || textFormEditor == null) {
        this.$set(this.titleQuestionState, questionIndex, false);
        return;
      }
      this.$set(this.titleQuestionState, questionIndex, null);
      if (this.isSubQuestion) {
        let request = {
          body: {
            title: textFormEditor,
          },
          questionId: this.mainQuestionId,
          subQuestionId: this.questionId,
          token: this.token,
        };
        this.updateSubQuestionInfo(request);

        this.questions[this.questionIndex].title = textFormEditor;
      } else {
        let request = {
          body: {
            title: textFormEditor,
          },
          questionId: this.questionId,
          token: this.token,
        };
        this.updateQuestionInfo(request);

        this.questions[this.questionIndex].title = textFormEditor;
      }
    },

    hideModal() {
      this.$refs["modal-editor"].hide();
    },

    async onShowAnswerSolution(questionId) {
      try {
        if (!this.hasAnswers[questionId]) {
          this.setNoSolution(questionId)
          return;
        }

        if (!this.isSubQuestion) {
          await this.fetchQuestionAnswer(questionId);
          this.setSolution({ 
            questionId: questionId, 
            solution : this.answer.solution 
          })
        } else {
          await this.fetchQuestionAnswer(this.mainQuestionId);

          for (const subQuestion of this.answer.subQuestions) {
            if (questionId === subQuestion.id) {
              this.setSolution({ 
                questionId: questionId, 
                solution : subQuestion.solution 
              })
              break;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style lang="css" scope>
.icon-middle {
  vertical-align: middle;
}

@media (min-width: 1000px) {
  .edit-question-modal .modal-dialog {
    max-width: 1140px !important;
  }
}

.handle {
  cursor: move;
}
.questions-background {
  padding: 20px;
  background: #ffffff 0% 0%;
  border: 1px solid #d3d9de;
  border-radius: 4px;
  opacity: 1;
}

.question-text {
  width: 100% !important;
  padding: 0 20px 0 0;
}

.question-info,
.desc-answer {
  display: flex;
  justify-content: space-between;
}

.edit-question {
  display: flex;
}

.exams-question {
  display: flex;
  font-weight: 700;
  font-size: 22px;
}

.exams-question-markdown {
  max-width: 50vw;
}

.choice-list {
  border-radius: 5px;
  box-shadow: 1px 1px 5px #d5d5d5;
  padding: 5px;
  margin: 10px 0;
  font-size: 14px;
}

.choice-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.choice-text-info {
  display: flex;
  align-items: center;
}

.question-list {
  width: 100%;
  padding: 5px 15px 5px 0;
}

.show-edit-question {
  display: flex;
}

.edit-question table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 12px 0 14px;
  color: #222;
  width: auto;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.edit-question table th {
  background-color: #555;
  font-weight: 300;
  color: #fff;
  padding-top: 6px;
}

.edit-question table th,
.edit-question table td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 14px 5px 12px;
  height: 32px;
}

.not-collapsed .up,
.down {
  display: block !important;
}
.collapsed .up,
.not-collapsed .down {
  display: none !important;
}
ul {
  padding-left: 0px;
  list-style: none;
  width: 100%;
}
.disabled-div {
  pointer-events: none;
  opacity: 0.4;
}
</style>
