<template>
  <b-container>
    <div class="bg-container">
      <div class="exams-topper">
        <h5 class="font-weight-bold">ข้อสอบทั้งหมดในระบบ</h5>
        <div class="exams-search">
          <search-box
            class="search-box-exams"
            title="พิมพ์ชื่อคอร์สเพื่อค้นหาข้อสอบ"
            @typed="getSearchKeyword"
            style="height: 30px"
          ></search-box>
        </div>
      </div>
    </div>

    <b-row class="mt-4 border-bt title-box">
      <b-col cols="2" class="text-center">
        <h5>Selected</h5>
      </b-col>
      <b-col cols="10">
        <h5>ข้อสอบ</h5>
      </b-col>
    </b-row>

    <template v-if="isLoading">
      <div class="text-center mt-5 text-csq">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
        <p>Loading...</p>
      </div>
    </template>
    <div
      v-else
      class="box-details"
      style="overflow-y: auto; height: 270px; overflow-x: hidden"
    >
      <b-row
        class="border-bt"
        v-for="exam in listExams"
        :key="exam.id"
        :busy="isLoading"
      >
        <b-col cols="2" class="text-center">
          <b-form-radio
            v-model="selectExam"
            :value="
              exam.quizExamId
                ? exam.quizExamId + ',' + exam.lecName
                : exam.title ? exam._id + ',' + exam.title : exam._id + ',' + exam.examName
            "
            :disabled="exam.courseName != null"
          ></b-form-radio>
        </b-col>
        <b-col cols="10">
          <h5>
            {{ exam.lecName || exam.examName || exam.title }}
          </h5>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-4">
      <b-col cols="6 ">
        <div class="d-flex justify-content-start align-items-center">
          <p class="pr-3">แสดง</p>
          <b-form-select
            v-model="selectedShow"
            style="width: 75px"
            @change="changeShowData"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </b-form-select>
          <p class="pl-3">จาก {{ rows }} รายการ</p>
        </div>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-exams"
          align="right"
          last-number
        ></b-pagination>
      </b-col>
    </b-row>

    <div class="editor-button mt-5">
      <b-button @click="onhideModel()" class="mr-1" variant="outline-primary"
        >ยกเลิก</b-button
      >
      <b-button
        :disabled="selectExam === ''"
        @click="onSave()"
        variant="primary"
        class="mx-2"
        >บันทึก
      </b-button>
    </div>
  </b-container>
</template>

<script>
import SearchBox from "@/components/shared/SearchBox";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SearchBox,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      comId: this.$store.state.Company.companyInfo.id,
      selectedShow: 5,
      selectExam: "",
      examId: "",
      examName: "",
      examUrl: "",
      isLoading: true,
    };
  },

  props: {
    lecId: Number,
    courseId: String,
  },
  mounted() {
    this.fetch();

    this.examUrl =
      process.env.VUE_APP_EXAM_PREVIEW_BASE_URL ||
      "https://exam-frontend-dev-26082018.coursesquare.co/embed/exam/";
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exams"]),

    rows() {
      return this.exams.length;
    },
    listExams() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }
      var showData = this.exams.slice(firstIndex, firstIndex + this.perPage);
      return showData;
    },
  },

  methods: {
    ...mapActions("ExamBuilder", ["fetchExams", "searchExams", "countSectionAndQuestion", "updateExamTakenLimit"]),
    ...mapActions("CourseManagement", [
      "updateLecture",
      "getCourseInfo",
      "fetchCourseModulesList",
      "createExamtoLecture",
    ]),

    changeShowData() {
      this.perPage = this.selectedShow;
    },
    async fetch() {
      this.isLoading = true;
      await this.fetchExams(this.comId).then(() => {});
      this.isLoading = false;
    },

    async getSearchKeyword(value) {
      this.q = value;
      this.isLoading = true;
      if (this.q) {
        await this.searchExams(this.q).then(() => {});
      } else {
        await this.fetchExams(this.comId).then(() => {});
      }
      this.isLoading = false;
    },
    onhideModel() {
      this.$emit("hideModalExam");
    },

    async onSave() {
      let str = this.selectExam.split(",");
      this.examId = str[0];
      this.examName = str[1];
      this.onhideModel();
      let requestLecUpdate = {
        body: {
          lecContent: {
            quiz_url: this.examUrl + this.examId,
            quiz_examId: this.examId,
          },
          lecId: this.lecId,
          lecType: "QUIZ",
        },
        courseId: this.courseId,
      };
      await this.createExamtoLecture(requestLecUpdate);
      await this.countSectionAndQuestion(this.examId)
      await this.updateExamTakenLimit(this.examId)

      let requestLecName = {
        courseId: this.courseId,
        lectureId: this.lecId,
        lectureName: this.examName,
        lectureType: "QUIZ",
      };
      await this.updateLecture(requestLecName).then(() => {
        this.countSectionAndQuestion(this.examId)
        this.updateExamTakenLimit(this.examId)
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.fetchCourseModulesList({
          courseId: this.courseId,
        });
      });
    },
  },
};
</script>

<style lang="scss" scope>
p {
  margin: 0px !important;
}
.border-bt {
  border-bottom: 1px solid #dfdfdf;
  margin-top: 15px;
  padding-bottom: 10px;
}
.title-box h5 {
  font-size: 18px;
  font-weight: bold;
}
.box-details h5 {
  font-size: 16px;
}
</style>