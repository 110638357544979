<template>
  <div style="font-family: 'Prompt', sans-serif">
    <b-table
      v-if="readonly"
      stacked
      borderless
      class="border"
      :fields="[
        { duration: 'ระยะเวลาเรียน :' },
        { price: 'ราคาคอร์ส :' },
        { promotion: 'ระยะเวลาโปรโมชั่น :' },
      ]"
      :items="[courseInfo]"
    >
      <template v-slot:cell(duration)="data">
        <span v-if="data.item.learnDuration">
          {{ data.item.learnDuration | formatNumber }} วัน
        </span>
        <span v-else>ไม่จำกัดวันเรียน</span>

        <span v-if="data.item.learnTimeLimit">
          (ไม่เกิน {{ data.item.learnTimeLimit | formatNumber }} ชั่วโมง)
        </span>
        <span v-else>ไม่จำกัดชั่วโมงเรียน</span>
      </template>
      <template v-slot:cell(price)="data">
        <div v-if="data.item.price">
          {{ data.item.price | formatNumber }} บาท
          <span
            v-if="
              data.item.promotionPrice && data.item.seasonPrice.enable === false
            "
            class="small"
          >
            (ลดเหลือ {{ data.item.promotionPrice | formatNumber }} บาท)
          </span>
          <span
            v-if="data.item.seasonPrice.price && data.item.seasonPrice.enable"
            class="small"
          >
            (ลดเหลือ {{ data.item.seasonPrice.price | formatNumber }} บาท)
          </span>
        </div>
        <div v-else>ฟรี</div>
      </template>
      <template v-slot:cell(promotion)="data">
        <div v-if="data.item.seasonPrice.price && data.item.seasonPrice.enable">
          {{ data.item.seasonPrice.startDate | formatDate }}<span> - </span>
          {{ data.item.seasonPrice.endDate | formatDate }}
        </div>
        <div v-else>-</div>
      </template>
    </b-table>
    <b-form v-else id="course-price" @submit="onSubmit">
      <b-row class="mt-4">
        <b-col>
          <b-form-group label="ระยะเวลาเรียน *">
            <b-input-group append="วัน">
              <b-form-input
                v-model="form.learnDuration"
                placeholder="ไม่จำกัด"
                :readonly="readonly"
                :value="courseInfo.learnDuration"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="จำนวนชั่วโมงจำกัด">
            <b-input-group append="ชั่วโมง">
              <b-form-input
                v-model="form.learnTimeLimit"
                placeholder="ไม่จำกัด"
                :value="courseInfo.learnTimeLimit"
                :readonly="readonly"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <b-form-group label="ราคาเต็ม *">
            <b-input-group append="บาท">
              <b-form-input
                v-model="price"
                :value="courseInfo.price"
                placeholder="ฟรี"
                :readonly="readonly"
                :formatter="editPrice"
              ></b-form-input>
            </b-input-group>
            <template v-slot:description>
              <div v-if="false" class="d-flex justify-content-between">
                <span>
                  {{ form.price | countVat | formatNumber }}
                  บาท ไม่รวมภาษี
                </span>
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col v-if="comId != 505 && !isHideCoursePriceManagement">
          <b-form-group label="ราคาลด (ราคาขายจริง)">
            <b-input-group prepend="ลดเหลือ" append="บาท">
              <b-form-input
                v-model="form.promotionPrice"
                :value="courseInfo.promotionPrice"
                :placeholder="price"
                :readonly="readonly"
                :formatter="editPrice"
                :state="checkVaildPromotionPrice"
              ></b-form-input>
            </b-input-group>
            <div
              v-if="checkVaildPromotionPrice === false"
              class="mt-1 text-danger"
              :style="{ fontSize: '0.8rem' }"
            >
              <span> ราคาลดต้องน้อยกว่าราคาเต็ม </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="comId != 505 && !isHideCoursePriceManagement">
        <b-col>
          <b-form-group>
            <b-form-checkbox switch class="mb-2" v-model="isPromotion">
              ราคาลดพิเศษ (ระบุช่วงโปรโมชั่น)
            </b-form-checkbox>
            <b-input-group prepend="ลดเหลือ" append="บาท">
              <b-form-input
                v-model="seasonPrice"
                :value="courseInfo.seasonPrice.price"
                :readonly="readonly"
                :formatter="editPrice"
                :disabled="isPromotion == false"
                :state="checkVaildSeasonPrice"
                aria-describedby="input-season-price"
              >
              </b-form-input>
            </b-input-group>
            <template v-slot:description>
              <div v-if="false" class="d-flex justify-content-between">
                <span>
                  {{ form.seasonPrice.price | countVat | formatNumber }}
                  บาท ไม่รวมภาษี
                </span>
              </div>
            </template>
            <div
              v-if="checkVaildSeasonPrice === false"
              class="mt-1 text-danger"
              :style="{ fontSize: '0.8rem' }"
            >
              <span> ราคาลดพิเศษต้องน้อยกว่าราคาเต็มและราคาลด และไม่เท่ากับ 0 </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="วันที่เริ่มโปรโมชั่น">
            <b-form-input
              v-model="coursePriceStartDate"
              type="date"
              :min="dateFormat"
              :max="coursePriceEndDate"
              :readonly="readonly"
              :disabled="isPromotion == false"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="วันที่สิ้นสุดโปรโมชั่น">
            <b-form-input
              v-model="coursePriceEndDate"
              type="date"
              :min="coursePriceStartDate"
              :readonly="readonly"
              :disabled="isPromotion == false"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-2" v-if="isPromotion">
          <b-form-group label="นับวันเรียน">
            <b-form-radio-group
              id="radio-check-learning"
              v-model="startDateType"
              stacked
            >
              <b-form-radio value="START_LEARNING"
                >วันที่เข้าเรียนวันแรก
                <span
                  :class="[
                    startDateType === 'START_LEARNING' && checkValidStartLearningSeasonPrice
                      ? 'text-danger text-sm'
                      : 'd-none',
                  ]"
                  >*ราคาลดพิเศษควรมากกว่าราคาลด(ราคาขายจริง) </span
                ></b-form-radio
              >
              <b-form-radio value="ENROLL"
                >วันที่ลงทะเบียน
                <span :class="[
                    startDateType === 'ENROLL' && checkValidEnrollSeasonPrice
                      ? 'text-danger text-sm'
                      : 'd-none',
                  ]"
                  >*ราคาลดพิเศษควรน้อยกว่าราคาลด(ราคาขายจริง)</span
                >
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="!readonly" class="text-right">
        <b-button
          class="mt-4"
          type="submit"
          variant="primary"
          :disabled="
            checkVaildPromotionPrice === false || checkVaildSeasonPrice === false
          "
          >บันทึก</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import moment from "moment";
import CustomUserPermissionMixin from "@/mixins/custom-user-permission-mixin.js";

export default {
  name: "PricingForm",
  mixins: [CustomUserPermissionMixin],
  filters: {
    countVat: function (value) {
      return value / 1.07;
    },

    formatNumber: function (value) {
      return Numeral(value).format("0,00");
    },

    formatDate: function (value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
  },
  props: {
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      memberId: JSON.parse(localStorage.getItem("user")).id,
      form: {
        exCoursePrice: 0,
        curCoursePrice: 0,
        coursePriceStartDate: null,
        coursePriceEndDate: null,
      },
      dateFormat: moment().format("YYYY-MM-DD"),
      periodDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
    coursePriceStartDate: {
      get() {
        const date =
          this.form.seasonPrice.startDate || moment().format("YYYY-MM-DD");
        return moment(date).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.seasonPrice.startDate = val;
      },
    },
    coursePriceEndDate: {
      get() {
        const date =
          this.form.seasonPrice.endDate ||
          moment().add(7, "days").format("YYYY-MM-DD");

        return moment(date).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.seasonPrice.endDate = val;
      },
    },
    price: {
      get() {
        return Numeral(this.form.price).format("0,0");
      },
      set(val) {
        this.form.price = val.replace(/[^0-9]|e/g, "");
      },
    },

    startDateType: {
      get() {
        return this.form.seasonPrice.startDateType;
      },
      set(val) {
        this.form.seasonPrice.startDateType = val;
      },
    },
    seasonPrice: {
      get() {
        return this.isPromotion
          ? Numeral(this.form.seasonPrice.price).format("0,0")
          : Numeral(0).format("0,0");
      },
      set(val) {
        this.form.seasonPrice.price = val.replace(/[^0-9]|e/g, "");
      },
    },
    curCoursePrice() {
      let curCoursePrice = 0;

      if (this.form.price > 0) {
        if (
          this.form.seasonPrice.price > this.form.price ||
          !this.isPromotion
        ) {
          curCoursePrice =
            this.form.promotionPrice != null
              ? this.form.promotionPrice
              : this.form.price;
        } else {
          curCoursePrice = this.form.seasonPrice.price;
        }
      }
      return curCoursePrice;
    },
    isPromotion: {
      get() {
        return this.form.seasonPrice.enable; //cast int to boolean
      },
      set(hasPromotion) {
        this.form.seasonPrice.enable = hasPromotion;
        if (hasPromotion) {
          this.form.seasonPrice.price =
            this.form.seasonPrice.price != null
              ? this.form.seasonPrice.price
              : this.form.price;
          this.form.seasonPrice.startDate =
            this.form.seasonPrice.startDate != null
              ? this.form.seasonPrice.startDate
              : moment().format("YYYY-MM-DD");
          this.form.seasonPrice.endDate =
            this.form.seasonPrice.endDate != null
              ? this.form.seasonPrice.endDate
              : moment().add(7, "days").format("YYYY-MM-DD");
        }
        return hasPromotion;
      },
    },
    checkVaildPromotionPrice() {
      let cPromoPrice = null;
      if (this.form.promotionPrice !== null) {
        cPromoPrice =
          Numeral(this.form.promotionPrice).value() === 0
            ? false
            : Numeral(this.form.promotionPrice).value() >=
              Numeral(this.form.price).value()
            ? false
            : true;
      }
      return cPromoPrice;
    },
    checkVaildSeasonPrice() {
      let state = null;
      if (this.isPromotion) {
        if (this.seasonPrice <= 0) {
          state = false;
        } else if (Numeral(this.form.promotionPrice).value()) {
          state =
            Numeral(this.form.seasonPrice.price).value() >=
            Numeral(this.form.promotionPrice).value()
              ? false
              : true;
        } else {
          state =
            Numeral(this.form.seasonPrice.price).value() >= Numeral(this.form.price).value()
              ? false
              : true;
        }
      }
      return state;
    },
    checkValidEnrollSeasonPrice() {
      if (this.form.promotionPrice) {
        return Numeral(this.form.seasonPrice.price).value() > Numeral(this.form.promotionPrice).value()
          ? true
          : false;
      }
      return false
    },
    checkValidStartLearningSeasonPrice() {
      return Numeral(this.form.seasonPrice.price).value() < Numeral(this.form.promotionPrice).value()
        ? true
        : false;
    },
    
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.courseInfo));
    if (this.form.promotionPrice !== null) {
      this.form.promotionPrice = Numeral(parseInt(this.form.promotionPrice)).format("0,0");
    }
  },
  methods: {
    ...mapActions("CourseManagement", [
      "updateCoursePrice",
      "getCourseInfo",
      "updateCourseInfo",
      "updateCoursePriceSeason",
    ]),
    editPrice(value) {
      var newValue = value.replace(/[^0-9]|e/g, "");
      if (isNaN(parseInt(newValue))) {
        return newValue;
      } else {
        return Numeral(parseInt(newValue)).format("0,0");
      }
    },
    async onSubmit(evt) {
      evt.preventDefault();
      await this.updateCoursePrice({
        courseId: this.$route.params.courseId,
        payloads: {
          courseLearnDuration: this.form.seasonPrice.enable
            ? this.form.seasonPrice.learnDuration
            : this.form.learnDuration,
          courseLearnTimeLimit: this.form.seasonPrice.enable
            ? this.form.seasonPrice.learnTimeLimit
            : this.form.learnTimeLimit,
          exCoursePrice: this.form.price || 0,
          curCoursePrice: this.curCoursePrice || 0,
          coursePriceStartDate:
            this.form.seasonPrice.startDate || moment().format("YYYY-MM-DD"),
          coursePriceEndDate:
            this.form.seasonPrice.endDate ||
            moment().add(7, "days").format("YYYY-MM-DD"),
        },
      });
      await this.updateCourseInfo({
        courseId: this.$route.params.courseId,
        attribute: {
          price: this.form.price,
          learnDuration: this.form.learnDuration,
          learningPeriodInDay: this.form.learnDuration,
          learnTimeLimit: this.form.learnTimeLimit,
          promotionPrice: Numeral(this.form.promotionPrice).value(),
        },
      });
      await this.updateCoursePriceSeason({
        courseId: this.$route.params.courseId,
        payloads: {
          learnDuration:
            this.form.seasonPrice.enable // if season price is enabled
            ? this.form.learnDuration // use learn duration filled in form
            : this.form.seasonPrice.learnDuration, // else, use learn duration from existing value (hidden in form)
          learnTimeLimit:
            this.form.seasonPrice.enable
            ? this.form.learnTimeLimit
            : this.form.seasonPrice.learnTimeLimit,
          enable: this.form.seasonPrice.enable,
          price: this.form.seasonPrice.price || this.form.price,
          startDate:
            this.form.seasonPrice.startDate || moment().format("YYYY-MM-DD"),
          endDate:
            this.form.seasonPrice.endDate ||
            moment().add(7, "days").format("YYYY-MM-DD"),
          startDateType: this.form.seasonPrice.startDateType,
        },
      }).then(() => {
        this.$emit("toggle-modal", "coursePriceModal");
      });
    },
  },
};
</script>
<style>
#radio-check-learning .custom-radio {
  margin-bottom: 0.5rem;
}
.text-sm {
  font-size: 14px;
}
</style>