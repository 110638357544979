<template>
  <div>
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">รูปบัตรประชาชน</h5>
        <br />
        <ImgUpload
          title="บัตรประชาชน"
          :endpoint="`/companies/${comId}/members/${mId}/images/id-card`"
          :config="idConfig"
          :path="card"
          :base="baseUrl"
          :src="`${baseUrl}/${card}?token=${token}`"
          :fd="idFd"
          imgFormName="idCard"
          @success="edit"
        />
        <br /><br />
      </div>
    </div>
    <b-card
      title="รูปใบหน้าสำหรับยืนยันตัวตน"
      title-tag="h5"
      class="bg-light border-0 no-shadow pt-3 my-3"
    >
      <b-form-group
        v-if="facePath == null"
        class="my-3"
        label="อัปโหลดรูปถ่ายหน้าตรง หันซ้าย หันขวา มุมเงย และมุมก้ม"
        label-for="face-image"
        :description="faceImageDescription"
        :state="faceImageUploadState"
      >
      </b-form-group>
      <!-- หน้าตรง -->
      <b-form-group
        v-if="faceFrontImage != null"
        class="my-3"
        label="รูปถ่ายหน้าตรง"
        label-for="face-image"
        :description="faceImageDescription"
      >
        <img :src="`${baseUrl}/${faceFrontImage}?token=${token}`" :style="imgStyle" class="card-img" alt="Face Front Image"/>
      </b-form-group>
      <!-- หน้าหันซ้าย -->
      <b-form-group
        v-if="faceLeftImage != null"
        class="w-12 w-md-6 my-3"
        label="รูปถ่ายหน้าหันซ้าย"
        label-for="face-image"
        :description="faceImageDescription"
      >
        <img :src="`${baseUrl}/${faceLeftImage}?token=${token}`" :style="imgStyle" class="card-img" alt="Face Left Image"/>
      </b-form-group>
      <!-- หน้าหันขวา -->
      <b-form-group
        v-if="faceRightImage != null"
        class="my-3"
        label="รูปถ่ายหน้าหันขวา"
        label-for="face-image"
        :description="faceImageDescription"
      >
        <img :src="`${baseUrl}/${faceRightImage}?token=${token}`" :style="imgStyle" class="card-img" alt="Face Right Image"/>
      </b-form-group>
      <!-- หน้ามุมเงย -->
      <b-form-group
        v-if="faceUpImage != null"
        class="my-3"
        label="รูปถ่ายหน้ามุมเงย"
        label-for="face-image"
        :description="faceImageDescription"
      >
        <img :src="`${baseUrl}/${faceUpImage}?token=${token}`" :style="imgStyle" class="card-img" alt="Face Up Image"/>
      </b-form-group>
      <!-- หน้ามุมก้ม -->
      <b-form-group
        v-if="faceDownImage != null"
        class="my-3"
        label="รูปถ่ายหน้ามุมก้ม"
        label-for="face-image"
        :description="faceImageDescription"
      >
        <img :src="`${baseUrl}/${faceDownImage}?token=${token}`" :style="imgStyle" class="card-img" alt="Face Down Image"/>
      </b-form-group>
      <!-- ปุ่มอัปโหลด -->
      <b-button
        @click="modalFaceImgMultiUploadShow()"
        class="create-new-link-button"
        variant="primary"
        :disabled="isLoading"
      >
        {{ getButtonText }}
      </b-button>
    </b-card>
    <b-modal
      centered
      v-model="ModalShowFaceImgMultiUpload"
      title="อัปโหลดรูปสำหรับยืนยันตัวตน"
      size="lg"
      :hide-footer="true"
      ref="modal-face-img-multi-upload"
    >
      <div>
        <ModalFaceImgMultiUpload
          :apiMethod="faceMethod"
          @hideModalFaceImgMultiUpload="hideModalFaceImgMultiUpload($event)"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import ImgUpload from "./ImgUpload";
import api from "@/services/api.js";
import axios from "axios";
import { mapActions } from "vuex";
import faceRecognitionService from "@/services/face-recognition-service";
import ModalFaceImgMultiUpload from "./FaceImgMultiUpload.vue";
export default {
  components: { ImgUpload, ModalFaceImgMultiUpload },
  data() {
    return {
      //Global Variables
      token: localStorage.getItem("token"),
      mId: this.$route.params.id,
      comId: localStorage.getItem("ComId"),
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com",
      faceBaseUrl:
        process.env.VUE_APP_FACE_RECOGNITION_API_URL ||
        "https://sightgain-dev-26082022.coursesquare.com/",
      //Local
      idConfig: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
      card: null,
      idFd: new FormData(),
      faceConfig: {
        "content-type": "multipart/form-data",
      },
      facePath: null,
      faceFd: new FormData(),
      faceMethod: null,
      verifier: JSON.parse(localStorage.getItem("user")).id,

      faceImage: null,
      faceImageDescription: null,
      faceImageUploadState: null,
      faceImages: [],
      userFaceUploadingStatus: false,
      faceFrontImage: null,
      faceLeftImage: null,
      faceRightImage: null,
      faceUpImage: null,
      faceDownImage: null,
      ModalShowFaceImgMultiUpload: false,
      isLoading: true,

      imgStyle: {
        width: '200px', // Set the width as per your requirement
        height: 'auto' // Automatically adjust the height while maintaining the aspect ratio
      }
    };
  },

  computed: {
    getUserFaceUploadingStatus() {
      if (this.userFaceUploadingStatus) {
        return true;
      } else {
        if (
          this.faceFrontImage &&
          this.faceLeftImage &&
          this.faceRightImage &&
          this.faceUpImage &&
          this.faceDownImage
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    getButtonText() {
      if (this.facePath != null) {
        return "แก้ไขรูปยืนยันตัวตน";
      } else {
        return "อัปโหลดรูปยืนยันตัวตน"; // Change this to the desired text when ccd_id is not null
      }
    }
  },

  mounted() {
    this.getId();
    this.getRegisterFace64();
    this.faceFd.append("data", `{"m_id":${this.mId}, "com_id":${this.comId}}`);
    this.getFaceImages();
  },
  methods: {
    ...mapActions("MemberManagement", [
      "uploadFaceImage",
      "uploadFaceImageSuccess",
    ]),
    edit(input) {
      this.update(input.value.idCardPath);
    },
    async update(idCardPath) {
      await api
        .post(`/v1/update-id-path/${this.mId}`, {
          idPath: idCardPath,
        })
        .then(() => {
          this.getId();
        });
    },
    editFace() {
      this.updateFaceStatus();
    },
    async updateFaceStatus() {
      await api
        .put(`/v1/faceverify/${this.mId}/approveFaceVerifyStatus`, {
          verifier: this.verifier,
          status: "APPROVED",
        })
        .then(() => {
          this.getRegisterFace64();
        });
    },
    async getId() {
      await api.get(`v1/members/${this.mId}`).then(({ data }) => {
        const profile = data.data.profiles.find(profile => profile.company.id == this.comId);
        this.card = profile ? profile.idPath : null;
      });
    },
    async getRegisterFace64() {
      await axios
        .get(
          `${this.faceBaseUrl}v2/register-face?m_id=${this.mId}&com_id=${this.comId}`
        )
        .then((data) => {
          if (data.data.member_id) {
            this.facePath = data.data.face_path ? data.data.face_path[0] : null;
            this.faceFrontImage = data.data.face_path ? data.data.face_path[0] : null;
            this.faceLeftImage = data.data.face_path ? data.data.face_path[1] : null;
            this.faceRightImage = data.data.face_path ? data.data.face_path[2] : null;
            this.faceUpImage = data.data.face_path ? data.data.face_path[3] : null;
            this.faceDownImage = data.data.face_path ? data.data.face_path[4] : null;
            this.faceMethod = "PUT";
          } else {
            this.faceMethod = "POST";
          }
        });
      this.isLoading = false;
    },

    modalFaceImgMultiUploadShow() {
      this.ModalShowFaceImgMultiUpload = true;
    },

    async hideModalFaceImgMultiUpload() {
      await this.getId();
      await this.getRegisterFace64();
      await this.faceFd.append("data", `{"m_id":${this.mId}, "com_id":${this.comId}}`);
      await this.getFaceImages();
      await this.$refs["modal-face-img-multi-upload"].hide();
    },

    async doUpload() {
      if (!this.faceImage) return false;

      this.faceImageDescription = "กำลังอัปโหลด..";
      this.faceImageUploadState = null;
      const response = await this.uploadFaceImage({
        memberId: this.memberId,
        imageFile: this.faceImage,
      });

      if (response.status === 200) {
        this.faceImage = null;
        this.faceImageDescription = null;
        this.faceImageUploadState = true;
        this.getFaceImages();
      }
    },
    getFaceImages() {
      faceRecognitionService
        .getRegisterFaceImages(this.memberId)
        .then(({ data }) => {
          if (data.message === "Data not found") return;
          else {
            this.faceImages = [];
            for (let key in data) {
              this.faceImages.push(`data:image/jpeg;base64, ${data[key]}`);
            }
          }
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },

    confirmResetFaceImages() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((isConfirmed) => {
          if (isConfirmed) {
            this.resetFaceImages();
          }
        })
        .catch(() => {
          // An error occurred
        });
    },

    resetFaceImages() {
      faceRecognitionService
        .resetFaceImages(this.memberId)
        .catch(({ response }) => {
          console.error(response);
        })
        .then(() => {
          this.faceImages = [];
        });
    },
  },
};
</script>
