<template>
  <div style>
    <b-row>
      <b-col cols>
        <layout>
          <template v-slot:header>
            <h6>ภาพรวมเกรด</h6>
          </template>
          <template v-slot:body>
            <div v-if="mockPieChart" :style="mockPieStyle">
              <h6 :style="mockPieText">ไม่มีข้อมูล</h6>
            </div>
            <div v-else style="padding: 0.5rem">
              <b-row>
                <b-col class="text-right d-none d-lg-block" cols="8" offset="2">
                  <p>จากผู้สอบจำนวน {{ total }} คน</p>
                </b-col>
                <b-col lg="6">
                  <PieChart
                    :chart-data="datacollection"
                    :options="option"
                    :height="200"
                  ></PieChart>
                </b-col>
                <b-col class="text-center d-lg-none mt-3" cols="12">
                  <p>จากผู้สอบจำนวน {{ total }} คน</p>
                </b-col>
                <b-col lg="6">
                  <template>
                    <div class="box-center">
                      <ul class="legend">
                        <li v-for="index in result.label.length" :key="index">
                          <div class="Box">
                            <div class="box-text">
                              <span v-if="(index == result.label.length)" :style="{ backgroundColor: color[4] }"></span>
                              <span v-else :style="{ backgroundColor: color[index - 1] }"></span>
                              <slot :name="index.toString()">
                                เกรด {{ result.label[index-1] }}
                                {{
                                  result.value && total
                                    ? ((result.value[index-1] / total) * 100).toFixed(2)
                                    : "0.00"
                                }}%
                              </slot>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </div>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/exam-api.js";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";

export default {
  mixins: [chartMockMixin],
  components: {
    PieChart,
    layout,
  },
  props: ["apiRoute"],
  data() {
    return {
      datacollection: {},
      option: {},
      total: 0,
      result: {},
      color:[
        '#2DA7B4',
        '#42B2BD',
        '#62C3CC',
        '#E0FCFF',
        '#BEBEBE'
      ]        
    };
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
  methods: {
    dynamicSlotName(index) {
      return `slot${index + 1}`;
    },
    async fetch() {
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.checkMockPieChart(
        this.result.value[0] +
          this.result.value[1] +
          this.result.value[2] +
          this.result.value[3] +
          this.result.value[4]
      );
      this.total = 0
      for (let i = 0; i < this.result.value.length; i++) {
          this.total += this.result.value[i];
      }
      this.fillData();
      this.fillOption();
    },
    fillData() {
      let data = this.result.value;
      this.datacollection = {
        labels: this.result.label,
        datasets: [
          {
            backgroundColor: [
              "#2DA7B4",
              "#42B2BD",
              "#62C3CC",
              "#E0FCFF",
              "#BEBEBE",
            ],
            data: data,
          },
        ],
        borderWidth: 5,
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000",
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
            formatter: (value) => {
              // if (value > 0) {
              //   let sum = 0;
              //   let dataArr = ctx.chart.data.datasets[0].data;
              //   dataArr.map((data) => {
              //     sum += data;
              //   });
              //   sum = sum.toString();
              //   sum = sum.split(/(?=(?:...)*$)/);
              //   sum = sum.join(",");
              //   return sum;
              // } else {
              //   value = "";
              if (value > 0) {
                return value;
              } else return null;
              // }
            },
          },
        },

        elements: {
          arc: {
            borderWidth: 0,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      };
    },
    getRandomInt() {
      let n = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      let n1 = Math.floor(Math.random() * (n - 0 + 1)) + 0;
      let n2 = n - n1;

      return { n1, n2 };
    },
  },
};
</script>

<style scoped>
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
.Box {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.box-center {
  height: 100%;

  display: flex;
  justify-content: left;
}
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
/* basic positioning */
.legend {
  padding: 0;
  list-style: none;
  margin: auto auto auto 0;
}
.legend li {
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.legend span {
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 1rem;
  margin-top: 5px;
}
</style>
