<template>
  <layout>
    <template v-slot:header v-if="perPage <= 10">
      <b-row>
        <b-col cols="6">
          <h6>ข้อมูลการชำระเงินของผู้เรียน</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <a
            :href="`/${company.url}/analytics/tfac/payment-summary`"
            target="_blank"
            ><h6>ดูทั้งหมด</h6></a
          >
        </b-col>
      </b-row>
    </template>
    <template v-slot:body>
      <div class>
        <div v-if="perPage > 10" class="mb-3">
          <b-row align-v="center">
            <b-col cols="12" md="6">
              <b-form-group label="ค้นหาวันที่ชำระเงิน">
                <b-row align-v="center">
                  <b-col cols="5"
                    ><b-input
                      v-model="paidDateFromSearch"
                      type="date"
                      :max="paidDateToSearch"
                  /></b-col>
                  <b-col cols="auto"><div>ถึง</div></b-col>
                  <b-col cols="5"
                    ><b-input
                      v-model="paidDateToSearch"
                      type="date"
                      :min="paidDateFromSearch"
                      :max="today"
                  /></b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="สถานะการชำระเงิน">
                <b-select v-model="status" :options="options" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-v="baseline">
            <b-col cols="12" md="6">
              <b-form-group label="ค้นหาวันที่ออกใบเสร็จ">
                <b-row align-v="center">
                  <b-col cols="5"
                    ><b-input
                      v-model="invoiceIssueDateFromSearch"
                      type="date"
                      :max="invoiceIssueDatetoSearch"
                  /></b-col>
                  <b-col cols="auto"><div>ถึง</div></b-col>
                  <b-col cols="5"
                    ><b-input
                      v-model="invoiceIssueDatetoSearch"
                      type="date"
                      :min="invoiceIssueDateFromSearch"
                      :max="today"
                  /></b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="3">
              <b-form-group label="ค้นหาเลขบัตรประชาชน">
                <b-form-input v-model="idCardSearch" />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="3">
              <b-form-group label="ค้นหาเลขที่คำสั่งซื้อ">
                <b-form-input v-model="orderIdSearch" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="10"> </b-col>
            <b-col cols="2" class="text-right">
              <b-button @click="exportData()" class="btn btn-download" href=""
                ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
              >
            </b-col>
          </b-row>
        </div>
        <b-table
          striped
          id="payment-summary-table"
          :per-page="perPage"
          :current-page="currentPage"
          table-class="tbNumber text-left "
          :items="filterData()"
          :fields="fields"
          small
          sort-icon-left
          responsive
          :busy="isBusy"
          hover
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(price)="data">
            <span>{{ data.item.coi_purchasePrice | formatNumber }}</span>
          </template>
          <template v-slot:cell(paidFlag)="data">
            <span>{{
              data.item.cancelFlag == true
                ? "ยกเลิก"
                : data.item.paidFlag
                ? "ชำระแล้ว"
                : "ยังไม่ชำระ"
            }}</span>
          </template>
          <template v-slot:cell(purchaseDate)="data">
            <span v-if="data.item.paidFlag">{{
              data.item.purchaseDate | thaiDate
            }}</span>
          </template>
          <template v-slot:cell(invoiceIssueDate)="data">
            <span>{{ data.item.co_invoiceIssueDate | thaiDate }}</span>
          </template>
          <template v-slot:cell(invoiceNumber)="data">
            <span>{{ data.item.co_invoiceNumber }}</span>
          </template>
          <template v-slot:cell(trackingId)="data">
            <span>{{ data.item.trackingId }}</span>
          </template>
        </b-table>
        <div style="text-align: center" v-if="result.length == 0">
          {{ "ไม่มีข้อมูล" }}
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="filterData().length"
          :per-page="perPage"
          aria-controls="payment-summary-table"
          v-if="perPage > 10"
        ></b-pagination>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
import moment from "moment";
import Numeral from "numeral";
export default {
  components: {
    layout
  },
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    }
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      result: [],
      company: {},
      options: [
        { value: "ALL", text: "ดูทั้งหมด" },
        { value: "CANCELED", text: "ยกเลิก" },
        { value: true, text: "ชำระแล้ว" },
        { value: false, text: "ยังไม่ชำระ" }
      ],
      status: "ALL",
      currentPage: 1,
      today: moment().format("YYYY-MM-DD"),
      rows: 0,
      paidDateFromSearch: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      paidDateToSearch: moment().format("YYYY-MM-DD"),
      invoiceIssueDateFromSearch: null,
      invoiceIssueDatetoSearch: null,
      idCardSearch: "",
      orderIdSearch: "",
      isBusy: true,
      search: null,
      fields: [
        { key: "orderId", label: "เลขที่คำสั่งซื้อ" },
        {
          key: "name",
          label: "ชื่อผู้เรียน"
        },
        {
          key: "idCard",
          label: "เลขบัตรประชาชน",
          class: "text-right"
        },
        {
          key: "c_courseCode",
          label: "รหัสคอร์ส",
          class: "text-right"
        },
        {
          key: "price",
          label: "ราคาคอร์ส",
          class: "text-right"
        },
        {
          key: "paidFlag",
          label: "สถานะการชำระเงิน",
          class: "text-right"
        },
        {
          key: "purchaseDate",
          label: "วันที่ชำระเงิน",
          class: "text-right"
        },
        {
          key: "invoiceNumber",
          label: "เลขที่ใบเสร็จ",
          class: "text-right"
        },
        {
          key: "invoiceIssueDate",
          label: "วันที่ออกใบเสร็จ",
          class: "text-right"
        },
        {
          key: "trackingId",
          label: "Tracking No.",
          class: "text-right"
        }
      ]
    };
  },
  methods: {
    filterData() {
      var data = this.result.filter(i => {
        let passFilter = true;
        if (this.paidDateFromSearch && this.paidDateToSearch) {
          passFilter =
            passFilter &&
            new Date(i.purchaseDate).setHours(0, 0, 0, 0) >=
              new Date(this.paidDateFromSearch).setHours(0, 0, 0, 0) &&
            new Date(i.purchaseDate).setHours(0, 0, 0, 0) <=
              new Date(this.paidDateToSearch).setHours(0, 0, 0, 0);
        }
        if (this.invoiceIssueDateFromSearch && this.invoiceIssueDatetoSearch) {
          passFilter =
            passFilter &&
            new Date(i.co_invoiceIssueDate).setHours(0, 0, 0, 0) >=
              new Date(this.invoiceIssueDateFromSearch).setHours(0, 0, 0, 0) &&
            new Date(i.co_invoiceIssueDate).setHours(0, 0, 0, 0) <=
              new Date(this.invoiceIssueDatetoSearch).setHours(0, 0, 0, 0);
        }
        if (this.idCardSearch != null && this.idCardSearch.length > 0) {
          passFilter =
            passFilter &&
            i.idCard != null &&
            i.idCard.toLowerCase().includes(this.idCardSearch.toLowerCase());
        }
        if (this.orderIdSearch != null && this.orderIdSearch.length > 0) {
          passFilter =
            passFilter &&
            i.orderId != null &&
            i.orderId.toLowerCase().includes(this.orderIdSearch.toLowerCase());
        }

        return passFilter;
      });
      if (this.status != "ALL")
        if (this.status == "CANCELED") {
          data = data.filter(i => i.cancelFlag == true);
        } else {
          data = data.filter(
            i => i.paidFlag == this.status && i.cancelFlag == false
          );
        }
      return data;
    },
    async exportData() {
      let value = [];
      let set = this.filterData();
      set.forEach(i => {
        let temp = {
          orderId: i.orderId,
          name: i.name,
          email: i.email,
          idCard: i.idCard,
          accNo: i.sm_membershipId,
          cpaNo: i.sm_cpaNo,
          purchaseDate: i.purchaseDate
            ? moment(i.purchaseDate).format("DD/MM/YYYY")
            : "",
          courseCode: i.c_courseCode,
          courses: i.c_name,
          price: i.coi_purchasePrice,
          paidFlag:
            i.cancelFlag == true
              ? "ยกเลิก"
              : i.paidFlag
              ? "ชำระแล้ว"
              : "ยังไม่ชำระ",
          paidDate: i.paidFlag
            ? i.paidDate
              ? moment(i.paidDate).format("DD/MM/YYYY")
              : ""
            : "",
          invoiceNumber: i.co_invoiceNumber,
          invoiceIssueDate: i.co_invoiceIssueDate
            ? moment(i.co_invoiceIssueDate).format("DD/MM/YYYY")
            : "",
          trackingId: i.trackingId,
          billingName:
            i.mba_type == "PERSON"
              ? i.mba_titleName + " " + i.mba_firstName + " " + i.mba_lastName
              : i.mba_corperateName,
          billingId: i.mba_idNo,
          billingAddress: this.getMemberBillingAddress(i),
          shippingName:
            i.msa_titleName + " " + i.msa_firstName + " " + i.msa_lastName,
          shippingPhone: i.msa_phone,
          shippingAddress: this.getMemberShippingAddress(i),
          cerStartDate: i.cerStartDate
            ? moment(i.cerStartDate).format("DD/MM/YYYY")
            : "",
          cerEndDate: i.cerEndDate
            ? moment(i.cerEndDate).format("DD/MM/YYYY")
            : ""
        };
        value.push(temp);
      });
      let key = [
        "เลขที่คำสั่งซื้อ",
        "ชื่อผู้เรียน",
        "อีเมล",
        "เลขบัตรประชาชน",
        "เลขทะเบียนผู้ทำบัญชี",
        "เลขทะเบียนผู้สอบบัญชี",
        "วันที่สั่งซื้อ",
        "รหัสคอร์ส",
        "ชื่อคอร์สเรียน",
        "ราคาคอร์ส",
        "สถานะการชำระเงิน",
        "วันที่ชำระเงิน",
        "เลขที่ใบเสร็จ",
        "วันที่ออกใบเสร็จ",
        "Tracking No.",
        "ชื่อในการออกใบกำกับภาษี",
        "เลขผู้เสียภาษี",
        "ที่อยู่ในการออกใบกำกับภาษี",
        "ชื่อในการจัดส่งใบกำกับภาษี",
        "เบอร์โทรในการจัดส่งใบกำกับภาษี",
        "ที่อยู่ในการจัดส่งใบกำกับภาษี",
        "วันที่เริ่มหลักสูตร",
        "วันที่จบหลักสูตร"
      ];
      let filename = "Revenue-Payment-Summary-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },

    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    isBangkok(postcode) {
      return (
        (postcode >= 10100 && postcode <= 10120) ||
        (postcode >= 10140 && postcode <= 10260) ||
        (postcode >= 10300 && postcode <= 10530) ||
        (postcode >= 10600 && postcode <= 10900)
      );
    },

    getMemberBillingAddress(data) {
      let isBangkok = this.isBangkok(data.mba_postcode);
      return (
        (data.mba_address1 ?? "") +
        (data.mba_address2 ? " " + data.mba_address2 : "") +
        (data.mba_officeName ? " " + data.mba_officeName : "") +
        (data.mba_buildingName ? " อาคาร" + data.mba_buildingName : "") +
        (data.mba_floo ? " ชั้น" + data.mba_floo : "") +
        (data.mba_number ? " " + data.mba_number : "") +
        (data.mba_moo ? " หมู่" + data.mba_moo : "") +
        (data.mba_village ? " " + data.mba_village : "") +
        (data.mba_soi ? " ซอย" + data.mba_soi : "") +
        (data.mba_street ? " ถนน" + data.mba_street : "") +
        (data.mba_subDistrict
          ? (isBangkok ? " แขวง" : " ตำบล") + data.mba_subDistrict
          : "") +
        (data.mba_district
          ? (isBangkok ? " เขต" : " อำเภอ") + data.mba_district
          : "") +
        (data.mba_province
          ? (isBangkok ? " " : " จังหวัด") + data.mba_province
          : "") +
        (data.mba_postcode ? " " + data.mba_postcode : "")
      );
    },

    getMemberShippingAddress(data) {
      let isBangkok = this.isBangkok(data.msa_postcode);
      return (
        (data.msa_address1 ?? "") +
        (data.msa_address2 ? " " + data.msa_address2 : "") +
        (data.msa_officeName ? " " + data.msa_officeName : "") +
        (data.msa_buildingName ? " อาคาร" + data.msa_buildingName : "") +
        (data.msa_floo ? " ชั้น" + data.msa_floo : "") +
        (data.msa_number ? " " + data.msa_number : "") +
        (data.msa_moo ? " หมู่" + data.msa_moo : "") +
        (data.msa_village ? " " + data.msa_village : "") +
        (data.msa_soi ? " ซอย" + data.msa_soi : "") +
        (data.msa_street ? " ถนน" + data.msa_street : "") +
        (data.msa_subDistrict
          ? (isBangkok ? " แขวง" : " ตำบล") + data.msa_subDistrict
          : "") +
        (data.msa_district
          ? (isBangkok ? " เขต" : " อำเภอ") + data.msa_district
          : "") +
        (data.msa_province
          ? (isBangkok ? " " : " จังหวัด") + data.msa_province
          : "") +
        (data.msa_postcode ? " " + data.msa_postcode : "")
      );
    }
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.filterData().length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
    this.company = this.$store.state.Company.companyInfo;
  }
};
</script>
<style scoped></style>
