<template>
  <div style="margin-left: 3em">
    <PageHeader
      style="margin-left: -3em"
      title="รายงานข้อมูลผู้เรียนรายบุคคล"
    ></PageHeader>
    <br />
    <div v-if="!isLoading">
      <h5>
        <router-link :to="`individual-student-report`"
          >รายชื่อผู้เรียน</router-link
        >
        / {{ individualStudentInfoData.mp_name }}
      </h5>
      <br />
      <b-container class="bv-example-row" fluid>
        <b-row>
          <b-col cols="2">
            <img
              style="vertical-align: baseline"
              height="100"
              :src="imgBaseUrl + individualStudentInfoData.c_thumbnailPath"
            />
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="4" class="mb-3">
                <strong>ชื่อผู้เรียน</strong><br />
                <p>
                  {{
                    individualStudentInfoData.mp_name
                      ? individualStudentInfoData.mp_name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>เลขทะเบียนผู้สอบบัญชี</strong><br />
                <p>
                  {{
                    individualStudentInfoData.เลขทะเบียนผู้สอบบัญชี
                      ? individualStudentInfoData.เลขทะเบียนผู้สอบบัญชี
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>ผู้ทำบัญชี</strong><br />
                <p>
                  {{
                    comId == 519
                      ? (individualStudentInfoData.sm_membershipId
                        ? individualStudentInfoData.sm_membershipId
                        : individualStudentInfoData.ผู้ทำบัญชี
                        ? individualStudentInfoData.ผู้ทำบัญชี
                        : "-")
                      : (individualStudentInfoData.ผู้ทำบัญชี
                        ? individualStudentInfoData.ผู้ทำบัญชี
                        : "-")
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>รหัสหลักสูตร</strong><br />
                <p>
                  {{
                    individualStudentInfoData.c_courseCode
                      ? individualStudentInfoData.c_courseCode
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>ชื่อหลักสูตร</strong><br />
                <p>
                  {{
                    individualStudentInfoData.c_name
                      ? individualStudentInfoData.c_name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>วิทยากร</strong><br />
                <p>
                  {{
                    individualStudentInfoData.omp_name
                      ? individualStudentInfoData.omp_name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>วันที่อนุมัติหลักสูตร</strong><br />
                <p>
                  {{
                    individualStudentInfoData.c_approveDate
                      ? individualStudentInfoData.c_approveDate
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col cols="4" class="mb-3">
                <strong>นับชั่วโมงด้วย</strong><br />
                <p>
                  CPD : บัญชี {{individualStudentInfoData.accAccHour}} ชั่วโมง {{individualStudentInfoData.accAccMinute}} นาที, จรรยาบรรณ {{individualStudentInfoData.accEthicHour}} ชั่วโมง {{individualStudentInfoData.accEthicMinute}} นาที, อื่นๆ {{individualStudentInfoData.accOtherHour}} ชั่วโมง {{individualStudentInfoData.accOtherMinute}} นาที  
                </p>
                <p>
                  CPA : บัญชี {{individualStudentInfoData.cpaAccHour}} ชั่วโมง {{individualStudentInfoData.cpaAccMinute}} นาที, จรรยาบรรณ {{individualStudentInfoData.cpaEthicHour}} ชั่วโมง {{individualStudentInfoData.cpaEthicMinute}} นาที, อื่นๆ {{individualStudentInfoData.cpaOtherHour}} ชั่วโมง {{individualStudentInfoData.cpaOtherMinute}} นาที
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <!-- Export -->
      <b-container class="mt-5" fluid>
        <TableStudentFileInfo
          :individualStudentInfoData="individualStudentInfoData"
        />
      </b-container>
    </div>
    <div v-else><b-spinner></b-spinner></div>
  </div>
</template>

<script>
import TableStudentFileInfo from "./TfacIndividualTableStudentFileInfo.vue";
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
export default {
  components: {
    PageHeader,
    TableStudentFileInfo,
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_S1_URL ||
        "https://s1-dev.coursesquare.com/",
      cerId: this.$route.query.cerId,
      individualStudentInfoData: [],
      isLoading: true,
      comId: localStorage.getItem("ComId"),
    };
  },
  mounted() {
    this.getIndividualStudentInfoData();
  },
  methods: {
    async getIndividualStudentInfoData() {
      await api
        .get(`v1/tfac/get-individual-student-info-data?cer_id=${this.cerId}`)
        .then(({ data }) => {
          this.individualStudentInfoData = data[0];
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
b,
p {
  font-family: "Prompt", sans-serif;
  color: #000;
}
</style>
