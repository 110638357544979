<template>
  <div style="margin-left: 3em">
    <PageHeader title="รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร"></PageHeader>
    <br />
    <strong>กรุณาระบุช่วงเวลาที่ต้องการดาวน์โหลดรายงาน</strong><br /><br />
    <div style="margin-bottom: 2em">
      <strong>ช่วงวันที่ซื้อ</strong><br /><br />
      <div style="width: 250px">
        <div style="width: 250px">
          <b-input-group>
            <b-form-input
              size="sm"
              type="text"
              :value="formattedDate"
              placeholder="ค้นหาช่วงวันที่ซื้อ"
              disabled
            />
            <b-input-group-prepend>
              <b-button size="sm" variant="primary" @click="showCalendarRange()"
                ><b-icon icon="calendar-range"></b-icon
              ></b-button>
            </b-input-group-prepend>
          </b-input-group>
          <DatePicker
            id="calendar-range"
            style="
              display: none;
              float: right;
              position: relative;
              will-change: transform;
              left: 0px;
            "
            v-model="range"
            is-range
          />
        </div>
      </div>
    </div>

    <div class="mb-4" style="width: 400px">
      <strong>หลักสูตรทั้งหมด</strong><br /><br />
      <v-select
        v-if="courses != null"
        :options="courses"
        label="courseName"
        v-model="courseData"
        placeholder="กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร..."
        @input="checkExportReport()"
        :clearable="false"
      />
      <v-select v-else placeholder="กำลังโหลดข้อมูล..." />
    </div>
    <br />

    <div>
      <b-button
        @click="exportReport()"
        id="export-excel"
        variant="primary"
        style="display: inline-block"
        disabled
        href=""
        ><i class="fa fa-angle-double-down" aria-hidden="true"></i>
        ดาวน์โหลดรายงาน
      </b-button>
      <b-spinner
        id="export-excel-loading"
        class="align-middle ml-3"
        style="display: none"
      ></b-spinner>
      <ag-grid-vue
        style="width: 100%; height: 800px; display: none"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        @grid-ready="onGridReady"
        :rowData="reportData"
        :excelStyles="excelStyles"
      ></ag-grid-vue>
    </div>
    <br />
    <strong style="color: #ffcc00"
      >หมายเหตุ: ระบบทำการ generate report เป็นไฟล์ .xlsx
      และดาวน์โหลดลงบนเครื่องของคุณ</strong
    >
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import api from "@/services/api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
export default {
  components: { DatePicker, PageHeader, vSelect, "ag-grid-vue": AgGridVue },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      range: {
        start: "",
        end: ""
      },
      selectedDate: {
        start: null,
        end: null
      },
      courses: null,
      reportData: "",
      courseData: "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร...",
      gridApi: null,
      columnApi: null,
      excelStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0"
          }
        },
        {
          id: "currencyFormat",
          numberFormat: {
            format: "#,##0.00 €"
          }
        },
        {
          id: "negativeInBrackets",
          numberFormat: {
            format: "$[blue] #,##0;$ [red](#,##0)"
          }
        },
        {
          id: "booleanType",
          dataType: "Boolean"
        },
        {
          id: "stringType",
          dataType: "String"
        },
        {
          id: "dateType",
          dataType: "DateTime"
        },
        {
          id: "dateISO",
          dataType: "DateTime",
          numberFormat: {
            format: "dd-mm-yyyy"
          }
        },
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "decimalFormat",
          numberFormat: {
            format: "#,##0.00"
          },
          alignment: {
            horizontal: "Right"
          }
        }
      ],
      columnDefs: [
        {
          headerName: "รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร",
          children: [
            { field: "ลำดับ", valueGetter: hashValueGetter },
            { headerName: "ชื่อหลักสูตร", field: "c_name" },
            { headerName: "วิทยากร", field: "cow_name" },
            {
              headerName: "ราคาหลักสูตรสำหรับสมาชิก",
              field: "cpm_price",
              cellClass: "decimalFormat"
            },
            {
              headerName: "ราคาหลักสูตรสำหรับบุคคลทั่วไป",
              field: "c_price",
              cellClass: "decimalFormat"
            },
            {
              headerName: "จำนวนผู้สมัครเรียนที่เป็นสมาชิก",
              field: "is_member"
            },
            {
              headerName: "จำนวนผู้สมัครเรียนที่เป็นบุคคลทั่วไป",
              field: "not_member"
            },
            {
              headerName: "จำนวนผู้สมัครรวมทั้งหมด",
              field: "all_enroll"
            },
            {
              headerName: "จำนวนผู้สมัครเรียนที่เป็นผู้ทำบัญชี",
              field: "is_dbd"
            },
            {
              headerName: "จำนวนผู้สมัครเรียนที่เป็นผู้สอบบัญชี",
              field: "is_cpa"
            }
          ]
        }
      ]
    };
  },
  async mounted() {
    await api.get(`v1/companies/${this.comId}/courses`).then(({ data }) => {
      this.courses = data.data;
    });
  },
  methods: {
    checkExportReport() {
      if (
        this.selectedDate.start &&
        this.selectedDate.end &&
        this.courseData != "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร..."
      ) {
        document.getElementById("export-excel").disabled = false;
        document.getElementById("export-excel").classList.remove("disabled");
      } else {
        document.getElementById("export-excel").disabled = true;
      }
    },
    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    formatApiDate() {
      this.selectedDate.start = moment(this.range.start).format("YYYY-MM-DD");
      this.selectedDate.end = moment(this.range.end).format("YYYY-MM-DD");
      this.checkExportReport();
    },
    async exportReport() {
      document.getElementById("export-excel-loading").style.display =
        "inline-block";
      document.getElementById("export-excel").disabled = true;
      await api
        .get(
          `v1/tfac/enroll-summary-by-course-report?reportStartDate=${this.selectedDate.start}&reportEndDate=${this.selectedDate.end}&courseId=${this.courseData.courseId}`
        )
        .then(({ data }) => {
          this.reportData = data;
        });
      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร",
          fileName: `รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร`
        });
        document.getElementById("export-excel-loading").style.display = "none";
        document.getElementById("export-excel").disabled = false;
        document.getElementById("export-excel").classList.remove("disabled");
        this.checkExportReport();
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  },
  computed: {
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        this.formatApiDate();
        return (
          moment(this.range.start).format("DD MMM YYYY") +
          " - " +
          moment(this.range.end).format("DD MMM YYYY")
        );
      } else {
        return "";
      }
    }
  }
};
let hashValueGetter = function(params) {
  return params.node.rowIndex + 1;
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>
