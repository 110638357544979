<template>
  <b-card :class="$options.name">
    <header slot="header" :class="[`${$options.name}__head`]">
      <div class="main-bar">
        <div class="row">
          <div class="col-lg-5">
            <h5 class="nav_top_align">
              <i class="fas fa-file-alt" aria-hidden="true" /> สถานะการอัปโหลด
            </h5>
          </div>
        </div>
      </div>
    </header>
    <b-container fluid :class="[`${$options.name}__content`]" class="px-3">
      <b-row>
        <b-col class="px-4">
          <b-container fluid class="py-2">
            <b-row>
              <b-col class="text-left px-0">
                <h5>ข้อสอบ {{ examName }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-table
                stacked
                :items="examInfo"
                :fields="fields"
                :class="[`${$options.name}__content__table`]"
              ></b-table>
            </b-row>
          </b-container>
        </b-col>
        <b-col class="px-4">
          <b-container fluid class="py-2">
            <b-row>
              <b-col class="text-left px-0">
                <h5>จัดการข้อสอบ</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left px-0">
                <b-form-checkbox
                  v-model="checkedCoursesSelection"
                  @change="showCourses"
                >
                  <span>ต้องการเพิ่มข้อสอบลงในคอร์สเรียน</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <template v-if="isLoading">
              <div class="text-center mt-5 text-csq">
                <b-spinner class="align-middle" variant="primary"></b-spinner>
                <p>Loading...</p>
              </div>
            </template>
            <template v-if="shouldShowCourses">
              <b-row class="my-4">
                <b-col>
                  <h5>เลือกคอร์ส</h5>
                  <b-form-select
                    v-model="selectedCourse"
                    :options="courses"
                    @change="handleSelectedCourse"
                  ></b-form-select>
                </b-col>
              </b-row>
            </template>
            <template v-if="shouldShowModules">
              <b-row class="my-4">
                <b-col>
                  <h5>เลือกบทเรียน</h5>
                  <b-form-select
                    v-model="selectedModules"
                    :options="modules"
                  ></b-form-select>
                </b-col>
              </b-row>
            </template>
            <template v-if="shouldShowConfirmationButton">
              <b-row class="my-4">
                <b-col>
                  <b-button @click="confirmExamCreation">ยืนยัน</b-button>
                </b-col>
              </b-row>
            </template>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import reduce from "lodash/reduce";
import map from "lodash/map";

export default {
  name: "ExamManagementConfirmation",
  data() {
    return {
      fields: [
        {
          key: "examName",
          label: "ชื่อข้อสอบ",
          sortable: false,
        },
        {
          key: "examCategory",
          label: "หมวดข้อสอบ",
          sortable: false,
        },
        {
          key: "examQuestionsCount",
          label: "จำนวนคำถาม",
          sortable: false,
        },
      ],
      checkedCoursesSelection: false,
      selectedCourse: null,
      selectedModules: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyCoursesData",
      "companyCoursesPending",
      "companyModulesData",
      "companyModulesPending",
      "companyInfo",
    ]),
    ...mapGetters("ExamManagement", [
      "companyUploadedCsvData",
      "createdExamError",
    ]),
    examId() {
      return this.companyUploadedCsvData.exam.id;
    },
    examName() {
      return this.companyUploadedCsvData.exam.title;
    },
    examCategory() {
      return this.companyUploadedCsvData.exam.category;
    },
    examInfo() {
      return [
        {
          examId: this.examId,
          examName: this.examName,
          examCategory: this.examCategory,
          examQuestionsCount: reduce(
            this.companyUploadedCsvData.exam.sections,
            (count, ech) => {
              return count + ech.questions.length;
            },
            0
          ),
        },
      ];
    },
    courses() {
      const mappedCourses = map(this.companyCoursesData.data, (ech) => {
        const { courseId: value, courseName: text } = ech;
        return {
          value,
          text,
        };
      });
      return [
        ...mappedCourses,
        {
          value: null,
          text: "เลือกคอร์สเรียน",
        },
      ];
    },
    modules() {
      const mappedModules = map(this.companyModulesData, (ech) => {
        const { id: value, name: text } = ech;
        return {
          value,
          text,
        };
      });
      return [
        ...mappedModules,
        {
          value: null,
          text: "เลือกบทเรียน",
        },
      ];
    },
    shouldShowModules() {
      return (
        this.checkedCoursesSelection &&
        this.selectedCourse &&
        !this.companyModulesPending
      );
    },
    shouldShowCourses() {
      return this.checkedCoursesSelection && !this.companyCoursesPending;
    },
    shouldShowConfirmationButton() {
      return this.selectedCourse && this.selectedModules;
    },
  },

  methods: {
    ...mapActions("Company", ["fetchCompanyCourses", "fetchCompanyModules"]),
    ...mapActions("ExamManagement", ["createOrAppendExam"]),
    ...mapActions("ExamBuilder", ["fetchExams", "searchExams", "updateExamTakenLimit", "countSectionAndQuestion"]),

    async showCourses() {
      if (!this.checkedCoursesSelection) {
        this.isLoading = true;
        await this.fetchCompanyCourses(this.companyInfo.id);
        this.isLoading = false;
      }
    },

    async handleSelectedCourse() {
      await this.fetchCompanyModules(this.selectedCourse);
    },
    async confirmExamCreation() {
      await this.createOrAppendExam({
        lecName: this.examName,
        lecType: "QUIZ",
        moduleId: this.selectedModules,
        courseId: this.selectedCourse,
        examId: this.examId,
      });
      await this.countSectionAndQuestion(this.examId)
      if (this.createdExamError) return;
      await this.updateExamTakenLimit(this.examId)
      await this.countSectionAndQuestion(this.examId)
      this.toExamManagementSuccess();
    },
    toExamManagementSuccess() {
      this.$router.push({
        name: "company.exam-management.success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_variables.scss";
.ExamManagementConfirmation {
  &__content {
    padding: 1rem 0;
  }
  .card-header {
    padding: 0 auto;
  }
}
</style>

<style lang="scss">
.ExamManagementConfirmation {
  &__content {
    padding: 1rem 0;
    &__table {
      tbody {
        tr {
          td {
            &::before {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}
</style>
