<template>
  <div>
    <header class="head">
      <div class="main-bar">
        <div class="row">
          <div class="col-lg-6 col-sm-4">
            <h4 class="nav_top_align text-dark font-weight-bold">
              <i class="fa fa-user" aria-hidden="true"></i> &nbsp; แก้ไขโปรไฟล์
            </h4>
          </div>
        </div>
      </div>
    </header>
    <div class="outer">
      <div class="inner bg-container">
        <div class="card card-csq no-shadow rounded-0">
          <div class="card-header border-bottom-0">
            <router-link :to="{ name: 'member.index' }" class="text-csq lead">
              <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
            </router-link>
            <span class="lead">
              {{ member.firstName }} {{ member.lastName }}
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-lg-3 m-t-25">
                <div
                  class="card bg-dark text-white mb-3 no-shadow"
                  style="max-width: 240px; margin: auto"
                >
                  <img :src="member.avatarUrl" class="card-img" alt />
                  <button
                    class="btn btn-dark text-light border-0"
                    v-show="false"
                  >
                    แก้ไขรูปภาพ
                    <i class="fa fa-pen text-csq" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="card border-0 bg-light mb-3 no-shadow">
                  <div class="card-header border-0 bg-transparent">
                    <h5 class="card-title">กลุ่ม</h5>
                  </div>
                  <div class="card-body pb-0">
                    <v-select
                      v-model="memberGroups"
                      multiple
                      :options="companyGroups"
                      label="name"
                      placeholder="เลือกกลุ่ม"
                      @input="setSelectedGroups"
                    ></v-select>
                  </div>
                  <div class="card-footer border-top-0 bg-light">
                    <button
                      class="btn btn-dark"
                      type="button"
                      @click="syncMemberGroups"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <b-tabs content-class="mt-3" style="width: 70%">
                <b-tab title="ข้อมูลผู้เรียน" active>
                  <div>
                    <member-form
                      :member="member"
                      :errors="errors"
                      @updated="update"
                      @passwordChanged="updatePassword"
                    ></member-form>
                  </div>
                </b-tab>
                <b-tab
                  title="ผู้ทำบัญชี/ผู้สอบบัญชี"
                  v-if="isAccountingType()"
                >
                  <TfacTab
                    :specialMembership="specialMembership"
                    @updated="updateSpecialMembership"
                  />
                </b-tab>
                <b-tab
                  title="ที่อยู่การชำระเงิน"
                  v-if="isAccountingType()"
                >
                  <PaymentAddressTab
                    v-if="paymentAddresssTabStat == null"
                    :billingAddressList="billingAddressList"
                    :shippingAddressList="shippingAddressList"
                    @addNewBillingAddress="addNewBillingAddress"
                    @editBillingAddress="editBillingAddress"
                    @addNewShippingAddress="addNewShippingAddress"
                    @editShippingAddress="editShippingAddress"
                    @backPaymentAddressList="backPaymentAddressList"
                  />
                  <BillingAddressFormTab
                    v-if="paymentAddresssTabStat == 'billingAddressForm'"
                    :memberId="memberId"
                    :billingAddress="billingAddress"
                    @backPaymentAddressList="backPaymentAddressList"
                  />
                  <ShippingAddressFormTab
                    v-if="paymentAddresssTabStat == 'shippingAddressForm'"
                    :memberId="memberId"
                    :shippingAddress="shippingAddress"
                    @backPaymentAddressList="backPaymentAddressList"
                  />
                </b-tab>
                <b-tab
                  title="ข้อมูลตัวแทน/นายหน้า"
                  v-if="isInsuranceType()"
                >
                  <InsuranceTab
                    :firstName="member.firstName"
                    :lastName="member.lastName"
                  />
                </b-tab>
                <b-tab
                  title="อัปโหลดรูปสำหรับยืนยันตัวตน"
                  v-if="isAccountingType() || isInsuranceType()"
                >
                  <ImgUploadTab />
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <div class="card-footer border-top-0">
            <router-link :to="{ name: 'member.index' }" class="text-csq">
              <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
              ย้อนกลับ
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberForm from "./shared/Form.vue";
import TfacTab from "./shared/TfacTab.vue";
import ImgUploadTab from "./shared/ImgUploadTab.vue";
import InsuranceTab from "./shared/InsuranceTab.vue";
import PaymentAddressTab from "./shared/PaymentAddressTab.vue";
import BillingAddressFormTab from "./shared/BillingAddressFormTab.vue";
import ShippingAddressFormTab from "./shared/ShippingAddressFormTab.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import api from "../../../services/api";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  components: { MemberForm, vSelect, ImgUploadTab, InsuranceTab, TfacTab, PaymentAddressTab, BillingAddressFormTab, ShippingAddressFormTab },
  props: { company: { type: Object, default: null } },
  mixins: [authMixin],

  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      memberId: this.$route.params.id,
      companyUrl: this.$route.params.company,
      member: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        avatarUrl: "https://s1.coursesquare.co/data/images/display.jpg",
        companies: [],
        dateOfBirth: null,
        department: null,
        groups: [],
        idCard: null,
        username: null,
        staffId: null,
        idCardExpireDate: null
      },
      specialMembership: {
        sm_membershipId: null,
        sm_cpaNo: null,
      },
      paymentAddresssTabStat: null,
      billingAddress: {},
      billingAddressList: [],
      shippingAddress: {},
      shippingAddressList: [],
      memberGroups: [],
      companyGroups: [],
      selectedGroups: [],
      errors: {},
    };
  },

  mounted() {
    this.getMember();
    this.getSpecialMembership();
    this.getMemberBillingAddress();
    this.getMemberShippingAddress();
    this.getMemberGroups();
    this.getCompanyGroups();
  },

  methods: {
    async getMember() {
      await api
        .get(`/v1/members/${this.memberId}`)
        .then(({ data }) => {
          this.member = data.data;
        })
        .catch(() => {});
    },
    async getSpecialMembership() {
      await api
        .get(`/v1/special-membership/${this.memberId}`)
        .then(({ data }) => {
          this.specialMembership = data.data;
        })
        .catch(() => {});
    },
    async getMemberBillingAddress() {
      await api
        .get(`/v1/members/${this.memberId}/billing-address`)
        .then(({ data }) => {
          this.billingAddressList = data;
        })
        .catch(() => {});
    },
    async getMemberShippingAddress() {
      await api
        .get(`/v1/members/${this.memberId}/shipping-address`)
        .then(({ data }) => {
          this.shippingAddressList = data;
        })
        .catch(() => {});
    },

    getCompanyGroups() {
      api
        .get(`/v1/companies/${this.company.id}/member-groups`)
        .catch(({ response }) => {
          console.error(response);
        })
        .then(({ data }) => {
          this.companyGroups = data.data;
        });
    },

    getMemberGroups() {
      api
        .get(`/v1/members/${this.memberId}/groups?com_id=${this.company.id}`)
        .catch(({ response }) => {
          console.error(response);
        })
        .then(({ data }) => {
          this.memberGroups = data.data;
        });
    },

    setSelectedGroups() {
      this.selectedGroups = this.memberGroups.map((x) => x.id);
    },

    async update(data) {
      await api
        .put(`/v1/members/${this.memberId}?company=${this.company.id}`, data)
        .then(() => {
          this.$bvToast.toast("บันทึกข้อมูลเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });

          this.isEditing = false;
        })
        .catch(({ response }) => {
          const result = JSON.parse(response.request.response);
          this.errors = result.errors;
          this.$toast.error(response.data.message, "Error", {
            timeout: 3000,
          });
          return false;
        });
    },
    async updateSpecialMembership(data) {
      await api
        .put(`/v1/special-membership/${this.memberId}`, data)
        .then(() => {
          this.$bvToast.toast("บันทึกข้อมูลเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });

          this.isEditing = false;
        })
        .catch(({ response }) => {
          const result = JSON.parse(response.request.response);
          this.errors = result.errors;
          this.$toast.error(response.data.message, "Error", {
            timeout: 3000,
          });
          return false;
        });
    },

    updatePassword(data) {
      api
        .put(
          `/v1/members/${this.memberId}/password/?company=${this.company.id}`,
          {
            password: data.password,
            password_confirmation: data.passwordConfirm,
          }
        )
        .then(() => {
          this.$bvToast.toast("เปลี่ยนรหัสผ่านเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });

          this.isEditing = false;
        })
        .catch(() => {
          this.$bvToast.toast("เปลี่ยนรหัสผ่านไม่สำเร็จ.", {
            variant: "danger",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });
          return false;
        });
    },

    syncMemberGroups() {
      api
        .post(`/v1/members/${this.memberId}/groups`, {
          groups: this.selectedGroups,
        })
        .catch(() => {
          this.$toast.error("ไม่สามารถบันทึกข้อมูลได้!", "Error", {
            timeout: 3000,
          });
        })
        .then(() => {
          this.$bvToast.toast("บันทึกข้อมูลเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });

          this.selectedGroups = [];
        });
    },
    async addNewBillingAddress() {
      this.billingAddress = null;
      this.paymentAddresssTabStat = "billingAddressForm";
    },
    async editBillingAddress(data) {
      this.paymentAddresssTabStat = "billingAddressForm";
      this.billingAddress = data;
    },
    async addNewShippingAddress() {
      this.shippingAddress = null;
      this.paymentAddresssTabStat = "shippingAddressForm";
    },
    async editShippingAddress(data) {
      this.paymentAddresssTabStat = "shippingAddressForm";
      this.shippingAddress = data;
    },
    async backPaymentAddressList() {
      await this.getMemberBillingAddress();
      await this.getMemberShippingAddress();
      this.paymentAddresssTabStat = await null;
    },
  },
};
</script>