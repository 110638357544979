<template>
  <div class="border h-100">
    <div>
      <div class="col-12 py-2 border-bottom">
        <div class="d-flex align-items-center justify-content-between">
          <div>ข้อสอบที่มีคนทำมากที่สุด</div>
          <div class="font-small">
            <a
              :href="`/${company.url}/analytics/exams/most-exams`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-table
          table-class="tbNumber text-left"
          style="overflow-x: auto;"
          :items="mostExams"
          :fields="fields"
          class="text-truncate py-2"
          small
          :per-page="10"
          sort-icon-left
          responsive="true"
          :busy="mostExams ? false : true"
          hover
          striped
        >
          <template v-slot:cell(exam)="data">
            <span>{{ data.item.examTitle | cut }}</span>
          </template>
          <template v-slot:cell(totalPass)="data">
            <span>{{ data.item.totalPassDistinctStudent.length }}</span>
          </template>
          <template v-slot:cell(totalStudent)="data">
            <span>{{ data.item.totalStudentDistinctStudent.length }}</span>
          </template>
          <template v-slot:cell(avgScore)="data">
            <span>{{ data.item.avgScore.toFixed(2) }}</span>
          </template>
          <template v-slot:cell(button)="data">
            <b-button variant="outline-primary" size="sm">
              <a
                :href="`/${company.url}/analytics/exam/${data.item._id}`"
                target="_blank"
                >ดูข้อมูล</a
              >
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import totalTimeUsedMixin from '@/components/pages/analytics/exam/mixins/totalTimeUsed.js';
import dateMixin from '@/components/pages/analytics/shared/mixins/date.js';

export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  props: {
    mostExams: Array,
  },
  data() {
    return {
      fields: [
        { key: 'exam', label: 'ข้อสอบ' },
        { key: 'totalPass', label: 'จำนวนผู้สอบผ่าน' },
        { key: 'totalStudent', label: 'จำนวนผู้เข้าสอบ' },
        { key: 'avgScore', label: 'คะแนนเฉลี่ย' },
        { key: 'button', label: '' },
      ],
      items: [],
      isLoading: true,
      company: null,
    };
  },

  created() {
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>

<style scoped></style>
