<template>
<div class="body">
    <div>
    <header class="head">
      <div class="main-bar">
        <h5 class="nav_top_align text-dark font-weight-bold title-head">
          สรุปภาพรวมข้อสอบ
        </h5>
      </div>
    </header>
  </div>

  <div style="margin: 0 1rem 0 1rem" :key="reloadKey">
    <b-row style="margin: 0 0 0 0">
      <div class="d-lg-flex mt-5 box-dete">
        <h6><b-link @click="toPageExamOverview()">สรุปภาพรวมข้อสอบ</b-link><a style="color : gray"> / {{exam.title}}</a></h6>
      </div>
    </b-row>
    <b-row style="margin: 0.5rem 0 0 0">
      <div class="d-lg-flex box-dete">
        <examInfo
              :apiRoute="`exams/${examId}/entire`"
              :apiRoute2="`exams/${examId}/get-first-answer-sheets`"
        ></examInfo>
      </div>
    </b-row>
    <b-row class="mt-3">
      <b-col lg="9" class="d-lg-flex mt-1 mt-lg-3 box-dete">
        <div >
         <strong>ข้อมูลของวันที่</strong>
        </div>
      </b-col>
      <b-col lg="3" class="summaryBy d-lg-flex mt-1 mt-lg-3 box-dete" style="padding-left : 23px">
        <div>
         <strong>ดูข้อมูลสรุปจาก</strong>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9" class="d-lg-flex mb-1 my-lg-3 box-dete">
        <b-form-datepicker
          :max="endDate"
          v-model="startDate"
          class="mr-3 date "
          style="padding-right: 30px"
        ></b-form-datepicker>
        <strong class="pr-3 d-flex align-items-center"><p>ถึง</p></strong>
        <b-form-datepicker
          :min="startDate"
          v-model="endDate"
          class="date"
          style="padding-right: 30px"
        ></b-form-datepicker>
      </b-col>
      <b-col lg="3" class="d-lg-flex mb-1 my-lg-3 box-dete">
        <strong class="summaryBy-mb">ดูข้อมูลสรุปจาก</strong>
        <b-form-select
            id="branch"
            class="mb-2 ml-lg-2 mb-sm-0"
            v-model="bestScoreSelected"
            :options="bestScore"
            @input="filterbestScore()"
          ></b-form-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12 mb-3">
        <b-row class="mr-10">
          <b-col lg="12">
            <scoreOverview
              :apiRoute="`exams/${examId}/get-score-overview-by-exam-id?startDate=${startDate}&endDate=${endDate}&bestScore=${bestScoreSelected}`"
              :apiRoute2="`exams/${examId}/get-first-answer-sheets`"
            ></scoreOverview>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4">
        <resultOverview
          class="mb-3 mt-3 mt-lg-0"
          :apiRoute="`exams/${examId}/get-result-overview-by-exam-id?startDate=${startDate}&endDate=${endDate}`"
          :apiRoute2="`/v1/analytics/courses/getCountEnrollByExamId?examId=${examId}&startDate=${startDate}&endDate=${endDate}`"
        ></resultOverview>
      </b-col>
      <b-col lg="4">
        <examGrade
          class="mb-3 mb-lg-0"
          :apiRoute="`exams/${examId}/get-answer-sheets?startDate=${startDate}&endDate=${endDate}`"
        ></examGrade>
      </b-col>
      <b-col lg="4" class="mb-3">
        <answerOverview
          :apiRoute="`exams/${examId}/get-answer-overview-by-exam-id?startDate=${startDate}&endDate=${endDate}&bestScore=${bestScoreSelected}`"
        ></answerOverview>
      </b-col>

      <!--  <b-col lg="7 order-2 order-lg-3">
        <scoreOverview
          :apiRoute="`exams/${examId}/get-score-overview-by-exam-id?startDate=${startDate}&endDate=${endDate}`"
        ></scoreOverview>
      </b-col> -->

      <!--     <b-col cols="4">
        
      </b-col> -->
    </b-row>
    <b-row class="mt-3">
      <b-col lg="12" class="mb-3">
        <examSection
          :apiRoute="`exams/${examId}/get-first-answer-sheets`"
          :perPage="10"
        ></examSection>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" class="mb-3">
        <recentSheet
          :apiRoute="`exams/${examId}/get-recent-answer-sheet?startDate=${startDate}&endDate=${endDate}&template=panel&bestScore=${bestScoreSelected}`"
          :perPage="10"
          :bestScoreSelected="bestScoreSelected"
        ></recentSheet>
      </b-col>
      <b-col lg="12">
        <questionOverview
          :apiRoute="`exams/${examId}/get-answer-stat-by-exam-id`"
          :perPage="10"
          :bestScoreSelected="bestScoreSelected"
        ></questionOverview>
      </b-col>
    </b-row>
  </div>
  </div>
</template>
<script>
// import PageHeader from "@/components/layouts/PageHeader.vue";
import examInfo from "./panel/IndividualExamInfoPanel";
import examSection from "./panel/IndividualExamSectionPanel";
import examGrade from "./panel/IndividualExamGradePanel";
import scoreOverview from "./panel/IndividualExamScoreOverviewPanel";
import answerOverview from "./panel/IndividualExamAnswerOverviewPanel";
import resultOverview from "./panel/IndividualExamResultOverviewPanel";
import questionOverview from "./panel/IndividualExamQuestionOverviewPanel";
import recentSheet from "./panel/IndividualExamRecentAnswerSheetPanel";
import moment from "moment";
import adminService from "@/services/api.js";
import api from "@/services/exam-api.js";
export default {
  components: {
    // PageHeader,
    examInfo,
    examSection,
    examGrade,
    scoreOverview,
    answerOverview,
    resultOverview,
    questionOverview,
    recentSheet,
  },
  data() {
    return {
      company: null,
      quizIds: null,
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      examId: this.$route.params.examId,
      exam: {},
      bestScore: [
        {value: false,text: "กระดาษคำตอบทั้งหมด"},
        {value: true,text: "เฉพาะคะแนนสูงสุดของผู้เรียน"}
      ],
      bestScoreSelected: false,
      reloadKey: 0
    };
  },
  
  async created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAvailableQuizIds();
    
    let res = await api.get(`exams/${this.examId}/entire`);
    this.exam = res.data;
  },
  methods: {
    async fetchAvailableQuizIds() {
      try {
        const endpoint = `/v1/analytics/companies/${this.company.id}/courseQuizIds`;
        const result = await adminService.get(endpoint);
        this.quizIds = result.data.data.map((el) => el.quizExamId);
        if (!this.quizIds.includes(this.examId)) {
          let url = `${window.location.origin}/${
            window.location.pathname.split("/")[1]
          }/un-authorized`;
          window.location.replace(url);
        }
      } catch (err) {
        console.log("fetchAvailableQuizIds error!", err);
      }
    },
    toPageExamOverview() {
      this.$router.push({
        name: "analytics.exam.overview",
      });
    },
    filterbestScore(){
      this.reloadKey += 1;
    }
  },
};
</script>


<style scoped>

@media (max-width: 998px) {
  .box-dete {
    width: 100%;
  } 
  .summaryBy{
    display: none;
  }
  .summaryBy-mb{
    display: block;
  }
}

@media (min-width: 997px) {
  .date {
    width: auto;
  }
  .head{
    margin-left: -6%;
  }
  .main-bar{
    margin-left : 5.5%
  }
  .date{
  min-width: 300px;
  }
  .summaryBy{
    display: block;
  }
  .summaryBy-mb{
    display: none;
  }
  .body{
    padding-right: 5%;
  }
}

p {
  margin-bottom: 0px !important;
}
</style>