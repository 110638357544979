<template>
  <form @submit.prevent="update">
    <div class="row align-items-center ml-3">
      <h5 class="card-title"> ที่อยู่สำหรับออกใบกำกับภาษี
        <b-button
          @click="newBillingAddress()"
          class="create-new-link-button"
          variant="primary"
        >
          <i class="fa fa-plus"></i> เพิ่มที่อยู่ใหม่
        </b-button> 
      </h5>
    </div>
    <div
      v-for="address in billingAddressList"
      :key="address.mba_id"
      class="card border-0 no-shadow bg-light mb-3"
    >
      <div class="card-body pb-0">
        <div class="row" style="padding-left: 10px; margin-bottom: 10px;">
          <div class="col-md-9" style="padding-left: 0;">
            <div style="display: flex; justify-content:baseline; margin-bottom: 10px;">
              <div v-if="address.mba_type == 'PERSON'" style="font-weight: 500;">
                {{ address.mba_titleName }}{{ address.mba_firstName }} {{ address.mba_lastName }}
              </div>
              <div v-if="address.mba_type == 'CORPERATE'" style="font-weight: 500;">
                {{ address.mba_corperateName }}
              </div>
              <div v-if="address.mba_type == 'CORPERATE'" style="background-color: gray; color: #fff; border-radius:3px; padding: 0 5px 0 5px; margin-left: 7px">
                {{ address.mba_withHoldingTaxFlag == 1 ? "นิติบุคคล (หักภาษี ณ ที่จ่าย 3%)" : "นิติบุคคล" }}
              </div>
              <div v-if="address.mba_type == 'PERSON'" style="background-color: gray; color: #fff; border-radius:3px; padding: 0 5px 0 5px; margin-left: 7px">
                {{ "บุคคลธรรมดา" }}
              </div>
            </div>
            <div>
              <!-- Your address details here -->
              {{address.mba_number ? address.mba_number : ""}} {{address.mba_buildingName ? 'อาคาร ' + address.mba_buildingName : ""}} {{address.mba_floor ? 'ชั้นที่ ' + address.mba_floor : ""}} {{address.mba_village ? 'หมู่บ้าน ' + address.mba_village : ""}} {{address.mba_moo ? 'หมู่ ' + address.mba_moo : ""}} {{address.mba_soi ? 'ซอย ' + address.mba_soi : ""}} {{address.mba_street ? 'ถนน ' + address.mba_street : ""}} {{ address.mba_province == 'กรุงเทพมหานคร' ? address.mba_subDistrict ? 'แขวง ' + address.mba_subDistrict : "" : address.mba_subDistrict ? 'ตำบล ' + address.mba_subDistrict : ""}} {{ address.mba_province == 'กรุงเทพมหานคร' ? address.mba_district ? 'เขต ' + address.mba_district : "" : address.mba_district ? 'อำเภอ ' + address.mba_district : ""}} {{ address.mba_province ? 'จังหวัด ' + address.mba_province : ""}} {{ address.mba_postcode ? address.mba_postcode : ""}}
            </div>
            <div>
              <b>{{address.mba_name ? 'ที่อยู่: ' + address.mba_name : ""}}</b>
            </div>
          </div>
          <div class="col-md-3">
            <b-button type="button" variant="outline-secondary" name="edit-icon-btn" class="edit-icon mr-3" @click="editBillingAddressData(address)">
              <i class="fas fa-edit"></i> แก้ไข
            </b-button>
            <b-button type="button" variant="outline-danger" class="delete-icon" @click="removeBillingConfirm(address)">
              <i class="fa fa-trash" aria-hidden="true"></i> ลบ
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center ml-3">
      <h5 class="card-title"> ที่อยู่สำหรับจัดส่งเอกสาร
        <b-button
          @click="newShippingAddress()"
          class="create-new-link-button"
          variant="primary"
        >
          <i class="fa fa-plus"></i> เพิ่มที่อยู่ใหม่
        </b-button> 
      </h5>
    </div>
    <div
      v-for="address in shippingAddressList"
      :key="address.mba_id"
      class="card border-0 no-shadow bg-light mb-3"
    >
      <div class="card-body pb-0">
        <div class="row" style="padding-left: 10px; margin-bottom: 10px;">
          <div class="col-md-9" style="padding-left: 0;">
            <div style="display: flex; justify-content:baseline; margin-bottom: 10px;">
              <div style="font-weight: 500;">
                {{ address.msa_titleName }}{{ address.msa_firstName }} {{ address.msa_lastName }}
              </div>
            </div>
            <div>
              <!-- Your address details here -->
              {{address.msa_number ? address.msa_number : ""}} {{address.msa_buildingName ? 'อาคาร ' + address.msa_buildingName : ""}} {{address.msa_floor ? 'ชั้นที่ ' + address.msa_floor : ""}} {{address.msa_village ? 'หมู่บ้าน ' + address.msa_village : ""}} {{address.msa_moo ? 'หมู่ ' + address.msa_moo : ""}} {{address.msa_soi ? 'ซอย ' + address.msa_soi : ""}} {{address.msa_street ? 'ถนน ' + address.msa_street : ""}} {{ address.msa_province == 'กรุงเทพมหานคร' ? address.msa_subDistrict ? 'แขวง ' + address.msa_subDistrict : "" : address.msa_subDistrict ? 'ตำบล ' + address.msa_subDistrict : ""}} {{ address.msa_province == 'กรุงเทพมหานคร' ? address.msa_district ? 'เขต ' + address.msa_district : "" : address.msa_district ? 'อำเภอ ' + address.msa_district : ""}} {{ address.msa_province ? 'จังหวัด ' + address.msa_province : ""}} {{ address.msa_postcode ? address.msa_postcode : ""}}
            </div>
            <div>
              {{address.msa_phone ? address.msa_phone : ""}}
            </div>
            <div>
              <b>{{address.msa_name ? 'ที่อยู่: ' + address.msa_name : ""}}</b>
            </div>
          </div>
          <div class="col-md-3">
            <b-button type="button" variant="outline-secondary" name="edit-icon-btn" class="edit-icon mr-3" @click="editShippingAddressData(address)">
              <i class="fas fa-edit"></i> แก้ไข
            </b-button>
            <b-button type="button" variant="outline-danger" class="delete-icon" @click="removeShippingConfirm(address)">
              <i class="fa fa-trash" aria-hidden="true"></i> ลบ
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        centered
        v-model="modalConfirmRemoveShippingAddress"
        size="lg"
        :hide-header="true"
        :hide-footer="true"
        ref="modal-remove-shipping-address"
      >
        <div>
          <confirmRemoveShippingAddress
            :shippingAddress="shippingAddress"
            :isConfirmed="isConfirmed"
            @modalHideRemoveShippingConfirm="modalHideRemoveShippingConfirm"
            @removeShippingAddressData="removeShippingAddressData"
          />
        </div>
    </b-modal>
    <b-modal
        centered
        v-model="modalConfirmRemoveBillingAddress"
        size="lg"
        :hide-header="true"
        :hide-footer="true"
        ref="modal-remove-billing-address"
      >
        <div>
          <ConfirmRemoveBillingAddress
            :billingAddress="billingAddress"
            :isConfirmed="isConfirmed"
            @modalHideRemoveBillingConfirm="modalHideRemoveBillingConfirm"
            @removeBillingAddressData="removeBillingAddressData"
          />
        </div>
    </b-modal>
  </form>
</template>

<script>
import api from "../../../../services/api.js"
import confirmRemoveShippingAddress from "./ConfirmRemoveShippingAddress.vue"
import ConfirmRemoveBillingAddress from "./ConfirmRemoveBillingAddress.vue"

export default {
  components: {confirmRemoveShippingAddress, ConfirmRemoveBillingAddress},
  props: {
    billingAddressList: { type: Array, default: null },
    shippingAddressList: { type: Array, default: null }
  },

  data() {
    return {
      memberId: this.$route.params.id,
      modalConfirmRemoveShippingAddress: false,
      modalConfirmRemoveBillingAddress: false,
      shippingAddress: null,
      billingAddress: null,
      isConfirmed: false,
      errorMessage: ""
    };
  },

  computed: {},

  mounted() {},

  methods: {
    newBillingAddress() {
      this.$emit("addNewBillingAddress");
    },

    editBillingAddressData(address) {
      if (address) {
        this.$emit("editBillingAddress", address);
      }
    },

    newShippingAddress() {
      this.$emit("addNewShippingAddress");
    },

    editShippingAddressData(address) {
      if (address) {
        this.$emit("editShippingAddress", address);
      }
    },

    removeShippingAddressData(msaId) {
      api
        .put(`/v1/members/${this.memberId}/shipping-address-delete/${msaId}`, {})
        .then(() => {
          this.isConfirmed = true;
          this.shippingAddress = null;
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },
    removeShippingConfirm(address) {
      this.shippingAddress = address;
      this.isConfirmed = false;
      this.modalConfirmRemoveShippingAddress = true;
    },
    modalHideRemoveShippingConfirm() {
      this.shippingAddress = null;
      this.isConfirmed = false;
      this.modalConfirmRemoveShippingAddress = false;
    },
    removeBillingAddressData(mbaId) {
      api
        .put(`/v1/members/${this.memberId}/billing-address-delete/${mbaId}`, {})
        .then(() => {
          this.isConfirmed = true;
          this.billingAddress = null;
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },
    removeBillingConfirm(address) {
      this.billingAddress = address;
      this.isConfirmed = false;
      this.modalConfirmRemoveBillingAddress = true;
    },
    modalHideRemoveBillingConfirm() {
      this.billingAddress = null;
      this.isConfirmed = false;
      this.modalConfirmRemoveBillingAddress = false;
    },
    backPaymentAddress() {
      this.$emit("backPaymentAddressList");
    },
  }
};
</script>
