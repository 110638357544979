<template>
  <div>
    <PageHeader title="รายการแจ้งโอนเงิน" />
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <div class="inner bg-container">
          <div class="card card-csq no-shadow">
            <div class="card-body pt-4">
              <b-row>
                <b-col cols="12">
                  <p class="pb-5">รายการแจ้งโอนเงิน {{ rows }} รายการ</p>
                </b-col>
              </b-row>
              <b-row class="pb-3" style="border-bottom: 1px solid #000">
                <b-col cols="2">
                  <p><strong>วันเวลาที่แจ้งโอน</strong></p>
                </b-col>
                <b-col cols="3">
                  <p><strong>ชื่อผู้เรียน</strong></p>
                </b-col>
                <b-col cols="3">
                  <p><strong>อีเมล</strong></p>
                </b-col>
                <b-col cols="2">
                  <p><strong>จำนวนเงินที่โอน</strong></p>
                </b-col>
                <b-col cols="2">
                  <p><strong>จำนวนคอร์ส</strong></p>
                </b-col>
              </b-row>

              <template v-if="isLoading">
                <div class="text-center my-4">
                  <b-spinner class="align-middle" variant="primary"></b-spinner>
                  <p>Loading...</p>
                </div>
              </template>

              <div class="box-details" v-else>
                <b-row
                  v-for="(transferorder,
                  transferorderIndex) in listCourseTransferOrder"
                  :key="transferorderIndex"
                  :class="[
                    activeIndex == transferorderIndex
                      ? 'active_row pt-2 mb-2'
                      : 'transferdetails py-2',
                    'onHover  ',
                  ]"
                >
                  <b-col cols="2">
                    <p>
                      {{ transferorder.btf_transferDateTime }}
                    </p>
                  </b-col>
                  <b-col cols="3">
                    <p>
                      {{ transferorder.fullName }}
                    </p>
                  </b-col>
                  <b-col cols="3">
                    <p>
                      {{ transferorder.m_email }}
                    </p>
                  </b-col>
                  <b-col cols="2">
                    <p>
                      {{
                        transferorder.btf_bahtAmount
                          ? formatNumber(transferorder.btf_bahtAmount)
                          : "-"
                      }}
                      บาท
                    </p>
                  </b-col>
                  <b-col cols="2">
                    <div class="d-flex justify-content-between">
                      <p>
                        {{ transferorder.item }}
                      </p>
                      <div>
                        <b-button
                          v-b-toggle="'collapse-transfer-' + transferorderIndex"
                          variant="primary"
                          @click="
                            activeIndex == transferorderIndex
                              ? rowToggleClick()
                              : rowClick(
                                  transferorderIndex,
                                  transferorder.co_orderId
                                )
                          "
                          >ดูข้อมูล</b-button
                        >
                      </div>
                    </div>
                  </b-col>

                  <b-collapse
                    :id="'collapse-transfer-' + transferorderIndex"
                    style="background-color: #fff"
                    accordion="my-accordion"
                    class="w-100"
                  >
                    <template v-if="isLoadingCourseTransfer">
                      <b-col cols="12" class="my-2">
                        <div class="text-center my-4">
                          <b-spinner
                            class="align-middle"
                            variant="primary"
                          ></b-spinner>
                          <p>Loading...</p>
                        </div>
                      </b-col>
                    </template>
                    <b-col cols="12" class="my-2" v-else>
                      <b-row>
                        <b-col cols="2"> </b-col>
                        <b-col cols="3">
                          <p><strong>ชื่อผู้แจ้งโอน</strong></p>
                        </b-col>
                        <b-col cols="3">
                          <p>
                            {{ courseTransferOrderDetails.memberFullName }}
                          </p>
                        </b-col>
                        <b-col cols="1"> </b-col>
                        <b-col cols="3">
                          <div class="d-flex justify-content-between">
                            <div>
                              <b-button
                                variant="success"
                                @click="
                                  confirmOrderPayment(transferorder.co_orderId)
                                "
                                >ยืนยันการแจ้งโอน</b-button
                              >
                            </div>
                            <div>
                              <b-button
                                @click="
                                  cancelOrderPayment(transferorder.co_orderId)
                                "
                                variant="danger"
                                >ยกเลิก</b-button
                              >
                            </div>
                          </div>
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>อีเมล</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>{{ courseTransferOrderDetails.memberEmail }}</p>
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>โทรศัพท์</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.memberMobilePhone
                                ? courseTransferOrderDetails.memberMobilePhone
                                : "-"
                            }}
                          </p>
                        </b-col>

                        <b-col v-if="comId == 519" cols="2"> </b-col>
                        <b-col
                          v-if="comId == 519"
                          cols="6 py-2"
                          style="border-bottom: 2px solid #c7c7c7"
                        >
                          <p>
                            <strong>ข้อมูลการโอน </strong
                            ><b-icon
                              @click="modalEditTransfer = !modalEditTransfer"
                              icon="pencil-square"
                              variant="primary"
                            ></b-icon>
                          </p>
                        </b-col>
                        <b-col v-if="comId == 519" cols="3 py-2"></b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>โอนเข้าบัญชี</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.bankName
                                ? courseTransferOrderDetails.bankName ==
                                  "QRPROMPTPAY"
                                  ? "QR code"
                                  : courseTransferOrderDetails.bankName
                                : "-"
                            }}
                          </p>
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>จำนวนเงินที่โอน</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.transferAmount
                                ? formatNumber(
                                    courseTransferOrderDetails.transferAmount
                                  )
                                : "-"
                            }}
                            บาท
                          </p>
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>เวลาที่แจ้งโอน</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.transferDateTime
                                ? courseTransferOrderDetails.transferDateTime
                                : "-"
                            }}
                          </p>
                        </b-col>

                        <b-col v-if="comId == 519" cols="2"> </b-col>
                        <b-col v-if="comId == 519" cols="3 py-2">
                          <p><strong>ใบแจ้งหนี้</strong></p>
                        </b-col>
                        <b-col v-if="comId == 519" cols="7 py-2">
                          <a
                            :href="
                              `${company.whitelabelUrl}/invoice/paymentInvoice?orderId=${courseTransferOrderDetails.orderId}`
                            "
                            target="_blank"
                            >ดูข้อมูลใบแจ้งหนี้</a
                          ><br />
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>หลักฐานการโอน</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p v-if="!checkSilp">-</p>
                          <a
                            v-else
                            href="javascript:void(0);"
                            @click="showImage"
                            >ดูสลิปโอนเงิน</a
                          ><br />
                          <img
                            v-if="showSlipImage"
                            :src="slipImageSrc"
                            alt="Payment Slip"
                            style="width: 250px;"
                          />
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>รหัสคูปอง</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.promoCode
                                ? courseTransferOrderDetails.promoCode
                                : "-"
                            }}
                          </p>
                        </b-col>

                        <b-col cols="2"> </b-col>
                        <b-col cols="3 py-2">
                          <p><strong>หมายเหตุ</strong></p>
                        </b-col>
                        <b-col cols="7 py-2">
                          <p>
                            {{
                              courseTransferOrderDetails.remark
                                ? courseTransferOrderDetails.remark
                                : "-"
                            }}
                          </p>
                        </b-col>
                      </b-row>

                      <div class="details-course">
                        <div class="mt-2">
                          <b-row style="border-bottom: 1px solid rgb(0, 0, 0)">
                            <b-col cols="6">
                              <p><strong>รายการ</strong></p>
                            </b-col>
                            <b-col cols="2">
                              <p><strong>จำนวนวันเรียน</strong></p>
                            </b-col>
                            <b-col cols="2">
                              <p><strong>นับวันเริ่มเรียน</strong></p>
                            </b-col>
                            <b-col cols="2">
                              <p><strong>ราคา</strong></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div>
                          <b-row
                            v-for="(transferorderItem,
                            transferorderItemIndex) in courseTransferOrderDetails.orderItem"
                            :key="transferorderItemIndex"
                            class="py-1"
                            :style="[
                              courseTransferOrderDetails.orderItem.length ===
                              transferorderItemIndex + 1
                                ? { 'border-bottom': '1px solid #000' }
                                : { 'border-bottom': '1px solid #dee2e6' },
                            ]"
                          >
                            <b-col cols="6">
                              <p>
                                {{ transferorderItem.c_name }}
                              </p>
                            </b-col>
                            <b-col cols="2">
                              <p>
                                เรียนได้
                                {{
                                  transferorderItem.coi_learnDuration
                                    ? transferorderItem.coi_learnDuration
                                    : "ไม่จำกัด"
                                }}
                                วัน
                              </p>
                            </b-col>
                            <b-col cols="2">
                              <p>
                                {{
                                  transferorderItem.coi_startDateType === null
                                    ? transferorderItem.comc_learningStartDateType ===
                                      "START_LEARNING"
                                      ? "วันที่เข้าเรียนวันแรก"
                                      : "วันลงทะเบียน "
                                    : transferorderItem.coi_startDateType ===
                                      "START_LEARNING"
                                    ? "วันที่เข้าเรียนวันแรก"
                                    : "วันลงทะเบียน "
                                }}
                              </p>
                            </b-col>
                            <b-col cols="2">
                              <p>
                                {{
                                  comId == 519
                                    ? formatNumber(
                                        transferorderItem.coi_price / 1.07
                                      )
                                    : transferorderItem.coi_price
                                    ? formatNumber(transferorderItem.coi_price)
                                    : formatNumber(
                                        transferorderItem.coi_purchasePrice
                                      )
                                }}
                                บาท
                              </p>
                            </b-col>
                          </b-row>
                        </div>
                        <b-row
                          v-if=" courseTransferOrderDetails.courseDiscountValue > 0 "
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p class="text-danger">
                              ส่วนลดจากโปรโมชั่น
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p class="text-danger">
                              -
                              {{
                                comId == 519
                                  ? formatNumber(
                                      courseTransferOrderDetails.courseDiscountValue /
                                      1.07
                                    )
                                  : formatNumber(
                                      courseTransferOrderDetails.courseDiscountValue
                                    )
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="
                            courseTransferOrderDetails.promoCode &&
                              courseTransferOrderDetails.promoPrice
                          "
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p class="text-danger">
                              คูปอง
                              {{ courseTransferOrderDetails.promoCode }}
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p class="text-danger">
                              -
                              {{
                                comId == 519
                                  ? formatNumber(
                                      courseTransferOrderDetails.coursePromoCodeDiscountValue /
                                      1.07
                                    )
                                  : formatNumber(
                                      courseTransferOrderDetails.coursePromoCodeDiscountValue
                                    )
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="courseTransferOrderDetails.tcdId == null && courseTransferOrderDetails.ccdId != null && courseTransferOrderDetails.courseConditionDiscountValue > 0"
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p class="text-danger">
                              ส่วนลด :
                              {{ courseTransferOrderDetails.ccdName }}
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p class="text-danger">
                              -
                              {{
                                comId == 519
                                  ? formatNumber(
                                      courseTransferOrderDetails.courseConditionDiscountValue /
                                      1.07
                                    )
                                  : formatNumber(
                                      courseTransferOrderDetails.courseConditionDiscountValue
                                    )
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="courseTransferOrderDetails.tcdId && courseTransferOrderDetails.courseConditionDiscountValue > 0"
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p class="text-danger">
                              ส่วนลด :
                              {{ courseTransferOrderDetails.tcdName }}
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p class="text-danger">
                              -
                              {{
                                comId == 519
                                  ? formatNumber(courseTransferOrderDetails.courseConditionDiscountValue / 1.07)
                                  : formatNumber(courseTransferOrderDetails.courseConditionDiscountValue)
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="comId == 519"
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p>
                              ภาษีมูลค่าเพิ่ม 7%
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p>
                              {{
                                formatNumber(
                                  courseTransferOrderDetails.orderPurchasePrice *
                                  (1 - 1 / 1.07)
                                )
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="
                            comId == 519 &&
                              courseTransferOrderDetails.orderWithHoldingTaxValue >
                                0
                          "
                          class="py-1"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <b-col cols="10">
                            <p class="text-danger">
                              หักภาษี ณ ที่จ่าย 3%
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <p class="text-danger">
                              -
                              {{
                                formatNumber(courseTransferOrderDetails.orderWithHoldingTaxValue)
                              }}
                              บาท
                            </p>
                          </b-col>
                        </b-row>

                        <div>
                          <b-row style="background-color: #007bff">
                            <b-col cols="10">
                              <p class="text-white">รวม</p>
                            </b-col>
                            <b-col cols="2">
                              <p class="text-white">
                                {{
                                  comId == 519
                                    ? formatNumber(
                                        courseTransferOrderDetails.orderPurchasePrice -
                                        courseTransferOrderDetails.orderWithHoldingTaxValue
                                      )
                                    : courseTransferOrderDetails.orderPurchasePrice
                                    ? formatNumber(
                                        courseTransferOrderDetails.orderPurchasePrice
                                      )
                                    : "-"
                                }}
                                บาท
                              </p>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                  </b-collapse>
                </b-row>
              </div>
            </div>
          </div>

          <b-row class="mt-4">
            <b-col cols="6 ">
              <div class="d-flex justify-content-start align-items-center">
                <p class="pr-3">แสดง</p>
                <b-form-select
                  v-model="selectedShow"
                  style="width: 75px"
                  @change="changeShowData"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </b-form-select>
                <p class="pl-3">จาก {{ rows }} รายการ</p>
              </div>
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-exams"
                align="right"
                last-number
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
      <div>
        <b-modal
          v-model="addModalShow"
          title="แจ้งเตือนยืนยันการแจ้งโอน"
          size="md"
          :hide-footer="true"
          centered
        >
          <ConfirmCourseOrderPayment />
        </b-modal>
      </div>

      <div>
        <b-modal
          v-model="modalEditTransfer"
          title="แก้ไขข้อมูลแจ้งโอนเงิน"
          hide-footer
        >
          <br />
          <div class="row">
            <div class="col" cols="12">
              <p class="transferTitle">โอนเข้าบัญชี</p>
              <b-form-select
                v-model="selectedBankName"
                :options="bankOptions"
              ></b-form-select>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col" cols="6">
              <p class="transferTitle">วันและเวลาแจ้งโอนเงิน</p>
              <div
                style="
                      display: inline-block;
                      position: relative;
                    "
              >
                <b-input-group>
                  <b-form-input
                    size="sm"
                    type="text"
                    :value="formattedDate"
                    placeholder="วันที่โอนเงิน"
                    disabled
                  />
                  <b-input-group-prepend>
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="showCalendar()"
                    >
                      <b-icon icon="calendar"></b-icon>
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <DatePicker
                  id="calendar"
                  style="
                        display: none;
                        float: right;
                        position: absolute;
                        will-change: transform;
                        left: 0px;
                      "
                  time-accuracy="3"
                  v-model="transferDate"
                  mode="dateTime"
                  is24hr
                />
              </div>
            </div>
            <div class="col" cols="6">
              <p class="transferTitle">จำนวนเงินที่โอน</p>
              <b-input-group append="บาท">
                <b-form-input
                  v-model="bathAmount"
                  placeholder="0.00"
                  :state="validateBathAmount"
                  @change="bathAmount = formatBathAmount(bathAmount)"
                  aria-describedby="bath-amount-warning"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="validateBathAmount">
                จำนวนเงินต้องมากกว่า 0.00 บาท
              </b-form-invalid-feedback>
            </div>
          </div>
          <br />
          <div style="float: right;">
            <b-button variant="primary" @click="updateTransferDetail()"
              >บันทึก</b-button
            >
          </div>
        </b-modal>
        <b-modal
          v-model="modalSuccessEditTransfer"
          hide-header
          hide-footer
          centered
        >
          <div style="text-align: center;">
            <b-icon
              icon="check-circle"
              variant="success"
              width="10%"
              height="10%"
            ></b-icon>
            <h5
              style="padding-top: 20px; padding-bottom: 30px; font-weight: 600;"
            >
              แก้ไขข้อมูลแจ้งโอนเงินเรียบร้อยแล้ว
            </h5>
            <b-button
              variant="primary"
              @click="
                modalSuccessEditTransfer = false;
                fetch();
                getCourseTransferOrderDetails(
                  courseTransferOrderDetails.orderId
                );
              "
            >
              เสร็จสิ้น
            </b-button>
          </div>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import Numeral from "numeral";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
  name: "CourseTransferMoneyList",
  components: {
    PageHeader,
    DatePicker,
  },

  data() {
    return {
      company: {},
      showSlipImage: false,
      slipImageSrc: null,
      currentPage: 1,
      perPage: 5,
      comId: this.$store.state.Company.companyInfo.id,
      memberId: JSON.parse(localStorage.getItem("user")).id,
      selectedShow: 5,
      activeIndex: null,
      addModalShow: false,
      modalEditTransfer: false,
      bankOptions: [],
      transferDate: "",
      selectedBankName: "",
      bathAmount: null,
      modalSuccessEditTransfer: false,
    };
  },

  mounted() {
    this.fetch();
    this.setCompanyInfo();
    this.getBankTransferList(this.comId).then(() => {});
  },

  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "isLoadingCourseTransfer",
      "courseTransferOrder",
      "courseTransferOrderDetails",
      "bankTransferList",
    ]),
    rows() {
      return this.courseTransferOrder.length;
    },

    formattedDate() {
      if (this.transferDate != null) {
        return moment(this.transferDate).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return null;
      }
    },

    validateBathAmount() {
      if (this.bathAmount <= 0) {
        return false;
      } else {
        return true;
      }
    },

    checkSilp() {
      if (this.courseTransferOrderDetails.paymentSlip) {
        return this.courseTransferOrderDetails.paymentSlip.includes(
          "drive.google"
        );
      } else {
        return false;
      }
    },

    listCourseTransferOrder() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }
      var showData = this.courseTransferOrder.slice(
        firstIndex,
        firstIndex + this.perPage
      );
      return showData;
    },
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseTransferOrder",
      "getCourseTransferOrderDetails",
      "confirmOrderPaymentTransfer",
      "cancelOrderPaymentTransfer",
      "getBankTransferList",
      "updateBankTransferDetail",
    ]),
    showCalendar() {
      let calendar = document.getElementById("calendar");
      if (calendar.style.display == "inline-block") {
        calendar.style.display = "none";
      } else {
        calendar.style.display = "inline-block";
      }
    },
    async updateTransferDetail() {
      let cpa_id = this.bankTransferList.find(
        (element) => element.cpa_BTBankName == this.selectedBankName
      ).cpa_id;
      let stringWithoutCommas = this.bathAmount.replace(/,/g, ""); // Remove commas

      let params = {
        orderId: this.courseTransferOrderDetails.orderId,
        body: {
          adminId: this.memberId,
          cpaId: cpa_id,
          bahtAmount: Number(stringWithoutCommas),
          transferDateTime: moment(this.transferDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        },
      };
      await this.updateBankTransferDetail(params);
      this.modalEditTransfer = false;
      this.modalSuccessEditTransfer = true;
    },
    showImage() {
      this.showSlipImage = true;
      this.slipImageSrc = this.courseTransferOrderDetails.paymentSlip.replace(
        "open?",
        "thumbnail?"
      );
      this.slipImageSrc += "&sz=w1000";
    },
    formatBathAmount(value) {
      let roundedValue = Math.round(value * 100) / 100;
      return roundedValue.toLocaleString("en-US");
    },
    formatNumber(value) {
      return Numeral(value).format("0,0.00");
    },
    confirmOrderPayment(orderId) {
      this.$bvModal
        .msgBoxConfirm(`คุณต้องการยืนยันการแจ้งโอนใช่หรือไม่ ?`, {
          title: "แจ้งเตือนยืนยันการแจ้งโอน",
          size: "md",
          buttonSize: "sm",
          okTitle: "ยืนยัน",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-primary",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm)
            this.confirmOrderPaymentTransfer(orderId).then(() => {
              this.getCourseTransferOrder();
            });
        });
    },

    cancelOrderPayment(orderId) {
      this.$bvModal
        .msgBoxConfirm(`คุณต้องการยกเลิกการแจ้งโอนใช่หรือไม่ ?`, {
          title: "แจ้งเตือนยกเลิกการแจ้งโอน",
          size: "md",
          buttonSize: "md",
          okTitle: "ยกเลิก",
          okVariant: "danger",
          cancelTitle: "ไม่ใช่ตอนนี้",
          cancelVariant: "outline-secondary",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm)
            this.cancelOrderPaymentTransfer(orderId).then(() => {
              this.getCourseTransferOrder();
            });
        });
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
    async fetch() {
      await this.getCourseTransferOrder().then(() => {});
    },

    async rowClick(index, orderId) {
      this.activeIndex = index;
      this.showSlipImage = false;
      this.slipImageSrc = null;
      await this.getCourseTransferOrderDetails(orderId).then(() => {});
      this.bankOptions = this.bankTransferList.map((element) => {
        return {
          value: element.cpa_BTBankName,
          text:
            element.cpa_BTBankName == "QRPROMPTPAY"
              ? "QR code"
              : element.cpa_BTBankName,
        };
      });
      this.transferDate = this.courseTransferOrderDetails.transferDateTime;
      this.selectedBankName = this.courseTransferOrderDetails.bankName;
      this.bathAmount = this.courseTransferOrderDetails.transferAmount;
    },

    rowToggleClick() {
      this.activeIndex = null;
      this.showSlipImage = false;
      this.slipImageSrc = null;
    },

    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
  },
};
</script>

<style lang="css" scoped>
.transferdetails:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.active_row {
  background-color: #e7f1ff;
}

.transferTitle {
  padding-bottom: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
